export const MutualFundsDetails = [{
  "name": "HDFC FLOATING RATE DEBT FUND -RETAIL OPTION- REGULAR PLAN - GROWTH",
  "id": 1071
}, {
  "name": "ICICI PRUDENTIAL SAVINGS FUND - RETAIL GROWTH",
  "id": 1616
}, {
  "name": "ICICI PRUDENTIAL CORPORATE BOND FUND - GROWTH",
  "id": 2270
}, {
  "name": "ICICI PRUDENTIAL FLOATING INTEREST FUND A - GROWTH",
  "id": 2302
}, {
  "name": "ICICI PRUDENTIAL FLOATING INTEREST FUND B - GROWTH",
  "id": 2308
}, {
  "name": "ICICI PRUDENTIAL FLOATING INTEREST FUND C - GROWTH",
  "id": 2313
}, {
  "name": "ICICI PRUDENTIAL MONEY MARKET FUND GROWTH",
  "id": 2380
}, {
  "name": "ICICI PRUDENTIAL BANKING AND PSU DEBT FUND - GROWTH",
  "id": 2382
}, {"name": "BANKING AND PSU DEBT RETAIL GROWTH", "id": 2401}, {
  "name": "ICICI PRUDENTIAL GILT FUND - GROWTH",
  "id": 2636
}, {
  "name": "ICICI PRUDENTIAL MEDIUM TERM BOND FUND B - GROWTH",
  "id": 2763
}, {
  "name": "ICICI PRUDENTIAL ALL SEASONS BOND FUND RETAIL - CUMULATIVE",
  "id": 2773
}, {
  "name": "ICICI PRUDENTIAL ALL SEASONS BOND FUND PREMIUM - CUMULATIVE",
  "id": 2774
}, {
  "name": "ICICI PRUDENTIAL ALL SEASONS BOND FUND - GROWTH",
  "id": 2790
}, {
  "name": "NIPPON INDIA ULTRA SHORT DURATION FUND - GROWTH OPTION",
  "id": 3230
}, {
  "name": "NIPPON INDIA CORPORATE BOND FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 3282
}, {"name": "DSP CREDIT RISK FUND - REGULAR PLAN - GROWTH", "id": 5883}, {
  "name": "AXIS LIQUID FUND - REGULAR GROWTH",
  "id": 7089
}, {"name": "AXIS SHORT DURATION FUND - RETAIL GROWTH", "id": 7146}, {
  "name": "MAGNUM INCOME FUND - 1998 GROWTH",
  "id": 8854
}, {
  "name": "SBI MAGNUM GILT FUND REGULAR GROWTH",
  "id": 8900
}, {
  "name": "SBI MAGNUM GILT FUND - LONG TERM - PF - REGULAR",
  "id": 8902
}, {
  "name": "SBI MAGNUM GILT FUND - LONG TERM - PF - FIXED PERIOD - 1 YEAR",
  "id": 8903
}, {
  "name": "SBI MAGNUM GILT FUND - LONG TERM - PF - FIXED PERIOD - 2 YEAR",
  "id": 8904
}, {
  "name": "SBI MAGNUM GILT FUND - LONG TERM - PF - FIXED PERIOD - 3 YEAR",
  "id": 8905
}, {
  "name": "SBI CONSERVATIVE HYBRID FUND REGULAR GROWTH",
  "id": 8936
}, {
  "name": "SBI-SHF- ULTRA SHORT TERM FUND - INSTITUTIONAL PLAN - GROWTH",
  "id": 9127
}, {
  "name": "SBI-SHDF-SHORT TERM-RETAIL PLAN-GROWTH",
  "id": 9150
}, {
  "name": "SBI INFLATION INDEXED BOND FUND - REGULAR PLAN - GROWTH",
  "id": 9175
}, {
  "name": "BANK OF INDIA SHORT TERM INCOME FUNDINSTITUTIONAL PLAN - GROWTH",
  "id": 9809
}, {
  "name": "BANK OF INDIA LIQUID FUNDINSTITUTIONAL PLAN - GROWTH",
  "id": 9825
}, {
  "name": "BANK OF INDIA LIQUID FUNDREGULAR PLAN - GROWTH",
  "id": 9829
}, {
  "name": "BANK OF INDIA LIQUID FUNDSUPER-INSTITUTIONAL PLAN - GROWTH",
  "id": 9831
}, {
  "name": "BANK OF INDIA CONSERVATIVE HYBRID FUND REGULAR PLAN - GROWTH",
  "id": 9846
}, {
  "name": "BANK OF INDIA ULTRA SHORT DURATIONINSTITUTIONAL PLAN - GROWTH",
  "id": 9865
}, {
  "name": "ADITYA BIRLA SUN LIFE MEDIUM TERM PLAN - GROWTH",
  "id": 10633
}, {
  "name": "ADITYA BIRLA SUN LIFE DYNAMIC BOND RETAIL-GROWTH",
  "id": 10669
}, {
  "name": "ADITYA BIRLA SUN LIFE SAVINGS FUND-GROWTH",
  "id": 10681
}, {
  "name": "ADITYA BIRLA SUN LIFE FLOATING RATE FUND - GROWTH",
  "id": 10723
}, {
  "name": "ADITYA BIRLA SUN LIFE ULTRA SHORT TERM-GROWTH",
  "id": 10766
}, {
  "name": "ADITYA BIRLA SUN LIFE FLOATING RATE FUND - REGULAR PLAN - GROWTH",
  "id": 10915
}, {
  "name": "ADITYA BIRLA SUN LIFE MONEY MANAGER FUND - GROWTH-REGULAR PLAN",
  "id": 11038
}, {
  "name": "ADITYA BIRLA SUN LIFE LIQUID FUND REGULAR PLAN - GROWTH",
  "id": 11040
}, {
  "name": "ADITYA BIRLA SUN LIFE MONEY MANAGER FUND - RETAIL - GROWTH",
  "id": 11081
}, {
  "name": "UTI LOW DURATION FUND-REGULAR GROWTH",
  "id": 11756
}, {
  "name": "UTI MEDIUM TO LONG DURATION FUND -REGULAR PLAN-GROWTH",
  "id": 11774
}, {
  "name": "UTI MONEY MARKET FUND-REGULAR GROWTH",
  "id": 11907
}, {
  "name": "UTI SHORT DURATION FUND-REGULAR PLAN-GROWTH",
  "id": 12030
}, {
  "name": "ICICI PRUDENTIAL SHORT TERM FUND - INSTITUTIONAL CUMULATIVE OPTION",
  "id": 13341
}, {
  "name": "ICICI PRUDENTIAL BOND FUND - INSTITUTIONAL GROWTH",
  "id": 13344
}, {
  "name": "CANARA ROBECO LIQUID FUND REGULAR GROWTH",
  "id": 16567
}, {
  "name": "KOTAK BOND UNIT SCHEME 99 DEPOSIT PLAN - GROWTH OPTION",
  "id": 18830
}, {
  "name": "KOTAK DYNAMIC BOND FUND REGULAR PLAN - GROWTH",
  "id": 18879
}, {
  "name": "ADITYA BIRLA SUN LIFE LOW DURATION FUND - INSTITUTIONAL PLAN - GROWTH",
  "id": 26135
}, {
  "name": "AXIS OVERNIGHT FUND REGULAR PLAN - GROWTH",
  "id": 31773
}, {
  "name": "UTI CHILDRENS HYBRID FUND-REGULAR GROWTH",
  "id": 32442
}, {
  "name": "BANK OF INDIA OVERNIGHT FUND REGULAR PLAN - GROWTH",
  "id": 36970
}, {
  "name": "ICICI PRUDENTIAL INDIA EQUITY FOF GROWTH",
  "id": 37023
}, {
  "name": "MIRAE ASSET ULTRA SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 39235
}, {
  "name": "ITI BANKING AND PSU DEBT FUND REGULAR PLAN GROWTH",
  "id": 39383
}, {
  "name": "PARAG PARIKH CONSERVATIVE HYBRID FUND - REGULAR PLAN GROWTH",
  "id": 50866
}, {
  "name": "SUNDARAM LOW DURATION FUND - REGULAR PLAN  GROWTH",
  "id": 93288
}, {
  "name": "SUNDARAM SHORT DURATION FUND  - REGULAR PLAN  GROWTH",
  "id": 93337
}, {
  "name": "SBI MAGNUM CHILDRENS BENEFIT FUND - SAVINGS PLAN REGULAR GROWTH",
  "id": 95732
}, {
  "name": "KOTAK ALL WEATHER DEBT FOF-REGULAR PLAN-GROWTH",
  "id": 98653
}, {
  "name": "QUANT OVERNIGHT FUND - REGULAR PLAN GROWTH - GROWTH",
  "id": 99523
}, {"name": "HSBC EQUITY SAVINGS FUND - GROWTH", "id": 100418}, {
  "name": "HDFC FOCUSED 30 FUND - REGULAR PLAN - GROWTH",
  "id": 100829
}, {
  "name": "HDFC BALANCED ADVANTAGE FUND - REGULAR PLAN - GROWTH",
  "id": 100834
}, {
  "name": "HDFC RETIREMENT SAVINGS FUND - EQUITY PLAN - REGULAR - GROWTH",
  "id": 100938
}, {
  "name": "ICICI PRUDENTIAL VALUE DISCOVERY FUND - GROWTH",
  "id": 101084
}, {
  "name": "ICICI PRUDENTIAL MULTI-ASSET FUND - GROWTH",
  "id": 101085
}, {
  "name": "ICICI PRUDENTIAL BLUECHIP FUND - GROWTH",
  "id": 101096
}, {
  "name": "ICICI PRUDENTIAL LARGE AND MID CAP FUND - GROWTH",
  "id": 101097
}, {
  "name": "ICICI PRUDENTIAL PHARMA HEALTHCARE AND DIAGNOSTICS (P.H.D) FUND GROWTH",
  "id": 101122
}, {
  "name": "ICICI PRUDENTIAL RETIREMENT FUND PURE EQUITY PLAN GROWTH",
  "id": 101137
}, {
  "name": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND GROWTH",
  "id": 101141
}, {
  "name": "ICICI PRUDENTIAL BHARAT 22 FOF - GROWTH",
  "id": 101226
}, {
  "name": "ICICI PRUDENTIAL EQUITY AND DEBT FUND - GROWTH",
  "id": 101377
}, {
  "name": "ICICI PRUDENTIAL INCOME OPTIMIZER FUND (FOF) - GROWTH",
  "id": 101386
}, {
  "name": "ICICI PRUDENTIAL INFRASTRUCTURE FUND - GROWTH",
  "id": 101410
}, {
  "name": "NIPPON INDIA LARGE CAP FUND - GROWTH PLAN - GROWTH",
  "id": 101439
}, {
  "name": "NIPPON INDIA CONSUMPTION FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 101477
}, {
  "name": "NIPPON INDIA ASSET ALLOCATOR FOF - REGULAR PLAN - GROWTH",
  "id": 101536
}, {
  "name": "NIPPON INDIA SMALL CAP FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 101644
}, {"name": "SBI LONG TERM EQUITY FUND - GROWTH", "id": 101683}, {
  "name": "SBI CONTRA FUND - REGULAR PLAN - GROWTH",
  "id": 101696
}, {
  "name": "SBI TAX ADVANTAGE FUND - SERIES I",
  "id": 101751
}, {
  "name": "SBI MAGNUM CHILDRENS BENEFIT FUND - INVESTMENT PLAN REGULAR GROWTH",
  "id": 101788
}, {
  "name": "INVESCO INDIA ARBITRAGE GROWTH",
  "id": 102004
}, {
  "name": "BANK OF INDIA ELSS TAX SAVER - REGULAR PLAN - GROWTH",
  "id": 102276
}, {
  "name": "BANK OF INDIA MID & SMALL CAP EQUITY & DEBT FUND REGULAR PLAN - GROWTH",
  "id": 102282
}, {
  "name": "KOTAK EQUITY ARBITRAGE FUND - REGULAR PLAN - GROWTH",
  "id": 102509
}, {
  "name": "KOTAK EQUITY SAVINGS FUND - REGULAR PLAN - GROWTH",
  "id": 102512
}, {
  "name": "KOTAK INFRASTRUCTURE AND ECONOMIC REFORM FUND STANDARD GROWTH",
  "id": 102519
}, {
  "name": "SUNDARAM FINANCIAL SERVICES OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 102879
}, {
  "name": "MOTILAL OSWAL MIDCAP FUND - REGULAR GROWTH",
  "id": 102982
}, {
  "name": "MOTILAL OSWAL LARGE AND MIDCAP FUND-REGULAR GROWTH",
  "id": 103013
}, {"name": "EDELWEISS AGGRESSIVE HYBRID FUND - GROWTH PLAN", "id": 103092}, {
  "name": "AXIS GOLD FUND - GROWTH",
  "id": 103270
}, {
  "name": "PARAG PARIKH FLEXI CAP FUND- REGULAR PLAN GROWTH",
  "id": 103783
}, {
  "name": "PARAG PARIKH ELSS TAX SAVER FUND - REGULAR PLAN-GROWTH",
  "id": 103785
}, {"name": "JM AGGRESSIVE HYBRID FUND - GROWTH OPTION", "id": 103797}, {
  "name": "JM LARGE CAP FUND - GROWTH OPTION",
  "id": 103803
}, {"name": "JM FLEXICAP FUND - GROWTH OPTION", "id": 103895}, {
  "name": "LIC MF INFRASTRUCTURE FUND-GROWTH-GROWTH",
  "id": 103957
}, {
  "name": "QUANT ABSOLUTE FUND REGULAR PLAN - GROWTH",
  "id": 104392
}, {
  "name": "QUANT LARGE AND MID CAP FUND REGULAR PLAN - GROWTH",
  "id": 104412
}, {
  "name": "QUANT SMALL CAP FUND REGULAR PLAN - GROWTH",
  "id": 104418
}, {
  "name": "QUANT FOCUSED FUND REGULAR PLAN - GROWTH",
  "id": 104436
}, {
  "name": "QUANT MID CAP FUND REGULAR PLAN - GROWTH",
  "id": 104442
}, {
  "name": "QUANT ELSS TAX SAVER FUND REGULAR PLAN - GROWTH",
  "id": 104458
}, {
  "name": "HDFC DYNAMIC PE RATIO FUND OF FUNDS - REGULAR PLAN - GROWTH (ERSTWHILE MORGAN STANLEY MULTI ASSET FUND)",
  "id": 104836
}, {
  "name": "QUANT MULTI ASSET FUND REGULAR PLAN - GROWTH",
  "id": 105012
}, {
  "name": "HDFC BANKING AND PSU DEBT FUND - REGULAR GROWTH OPTION",
  "id": 395
}, {
  "name": "HDFC FLOATING RATE DEBT FUND - REGULAR PLAN - GROWTH",
  "id": 645
}, {
  "name": "HDFC LIQUID FUND - PREMIUM PLUS PLAN - GROWTH",
  "id": 657
}, {
  "name": "HDFC CORPORATE BOND FUND - GROWTH OPTION",
  "id": 683
}, {
  "name": "HDFC SHORT TERM DEBT FUND - REGULAR PLAN - GROWTH",
  "id": 694
}, {
  "name": "HDFC RETIREMENT SAVINGS FUND - HYBRID DEBT PLAN - REGULAR - GROWTH",
  "id": 959
}, {"name": "HDFC LIQUID FUND - PREMIUM PLAN - GROWTH", "id": 989}, {
  "name": "HDFC HYBRID DEBT FUND - GROWTH OPTION",
  "id": 1083
}, {
  "name": "ICICI PRUDENTIAL BOND FUND - GROWTH",
  "id": 2231
}, {
  "name": "ICICI PRUDENTIAL CORPORATE BOND FUND RETAIL GROWTH",
  "id": 2257
}, {
  "name": "ICICI PRUDENTIAL LIQUID FUND - GROWTH",
  "id": 2362
}, {
  "name": "ICICI PRUDENTIAL MONEY MARKET FUND - GROWTH",
  "id": 2372
}, {
  "name": "ICICI PRUDENTIAL SHORT TERM FUND - GROWTH OPTION",
  "id": 2614
}, {
  "name": "ICICI PRUDENTIAL DEBT MANAGEMENT FUND (FOF) - GROWTH",
  "id": 2696
}, {
  "name": "ICICI PRUDENTIAL REGULAR SAVINGS FUND - GROWTH",
  "id": 2748
}, {
  "name": "ICICI PRUDENTIAL MEDIUM TERM BOND FUND - GROWTH",
  "id": 2755
}, {
  "name": "NIPPON INDIA MONEY MARKET FUND - GROWTH PLAN GROWTH OPTION",
  "id": 3226
}, {"name": "NIPPON INDIA CREDIT RISK FUND - GROWTH PLAN", "id": 3306}, {
  "name": "INVESCO INDIA LIQUID FUND - GROWTH",
  "id": 4067
}, {"name": "BANDHAN BANKING AND PSU DEBT FUND-REGULAR PLAN-GROWTH", "id": 5063}, {
  "name": "DSP GILT FUND - GROWTH",
  "id": 5858
}, {
  "name": "MIRAE ASSET LIQUID FUND - GROWTH OPTION",
  "id": 5958
}, {
  "name": "SUNDARAM GROWTH FUND INSTITUTIONAL PLAN GROWTH",
  "id": 6138
}, {
  "name": "SUNDARAM EQUITY HYBRID FUND INSTITUTIONAL GROWTH",
  "id": 6186
}, {
  "name": "AXIS BANKING AND PSU DEBT FUND - REGULAR GROWTH",
  "id": 7083
}, {
  "name": "AXIS SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 7144
}, {
  "name": "AXIS TREASURY ADVANTAGE FUND - REGULAR GROWTH",
  "id": 7161
}, {"name": "AXIS GILT FUND - REGULAR GROWTH OPTION", "id": 7190}, {
  "name": "AXIS STRATEGIC BOND FUND - REGULAR GROWTH",
  "id": 7200
}, {
  "name": "SBI MAGNUM INCOME FUND - 1998 - GROWTH",
  "id": 8860
}, {"name": "SBI MAGNUM MEDIUM DURATION FUND REGULAR GROWTH", "id": 9095}, {
  "name": "SBI - DYNAMIC BOND FUND - GROWTH",
  "id": 9109
}, {
  "name": "SBI MAGNUM LOW DURATION FUND REGULAR GROWTH",
  "id": 9133
}, {
  "name": "SBI SHORT TERM DEBT FUND - REGULAR PLAN - GROWTH",
  "id": 9144
}, {
  "name": "TATA TREASURY ADVANTAGE FUND REGULAR PLAN GROWTH",
  "id": 9335
}, {
  "name": "TATA MONEY MARKET FUND REGULAR PLAN GROWTH",
  "id": 9696
}, {
  "name": "BARODA BNP PARIBAS CREDIT RISK FUND (SCHEME HAS ONE SEGREGATED PORTFOLIO) REGULAR GROWTH",
  "id": 10082
}, {
  "name": "MAHINDRA MANULIFE LIQUID FUND - REGULAR - GROWTH",
  "id": 10343
}, {
  "name": "ADITYA BIRLA SUN LIFE CORPORATE BOND FUND - GROWTH-REGULAR PLAN",
  "id": 10667
}, {
  "name": "ADITYA BIRLA SUN LIFE SHORT TERM FUND - GROWTH",
  "id": 10685
}, {
  "name": "ADITYA BIRLA SUN LIFE GOVERNMENT SECURITIES FUND-GROWTH",
  "id": 10705
}, {
  "name": "ADITYA BIRLA SUN LIFE GOVERNMENT SECURITIES FUND-INSTANT GAIN-GROWTH",
  "id": 10707
}, {
  "name": "ADITYA BIRLA SUN LIFE BANKING AND PSU DEBT FUND - GROWTH-REGULAR PLAN",
  "id": 10961
}, {"name": "ADITYA BIRLA SUN LIFE SAVINGS FUND - GROWTH", "id": 11034}, {
  "name": "UTI CREDIT RISK FUND - GROWTH",
  "id": 11780
}, {"name": "UTI LIQUID FUND - REGULAR PLAN GROWTH", "id": 11792}, {
  "name": "UTI GILT FUND-REGULAR REGULAR GROWTH",
  "id": 11844
}, {
  "name": "UTI OVERNIGHT FUND - GROWTH PLAN",
  "id": 11858
}, {
  "name": "UTI CONSERVATIVE HYBRID FUND-REGULAR PLAN - GROWTH PLAN",
  "id": 11894
}, {
  "name": "QUANTUM DYNAMIC BOND FUND - REGULAR PLAN GROWTH OPTION",
  "id": 17479
}, {
  "name": "FRANKLIN INDIA BANKING AND PSU DEBT FUND -GROWTH",
  "id": 18068
}, {"name": "KOTAK MONEY MARKET FUND - GROWTH", "id": 18956}, {
  "name": "KOTAK DEBT HYBRID FUND - GROWTH",
  "id": 18960
}, {"name": "KOTAK CORPORATE BOND FUND STANDARD GROWTH", "id": 19273}, {
  "name": "HSBC LIQUID  FUND - GROWTH",
  "id": 19753
}, {
  "name": "HSBC FLEXI DEBT FUND - REGULAR GROWTH",
  "id": 19797
}, {
  "name": "EDELWEISS MONEY MARKET FUND - INSTITUTIONAL PLAN - GROWTH OPTION",
  "id": 20119
}, {"name": "360 ONE DYNAMIC BOND FUND REGULAR PLAN GROWTH", "id": 20637}, {
  "name": "JM LOW DURATION FUND - GROWTH",
  "id": 21360
}, {
  "name": "AXIS CORPORATE BOND FUND - REGULAR PLAN - GROWTH",
  "id": 22080
}, {
  "name": "BARODA BNP PARIBAS ULTRA SHORT DURATION FUND - REGULAR PLAN GROWTH",
  "id": 24477
}, {"name": "UTI CORPORATE BOND FUND - REGULAR GROWTH PLAN-GROWTH", "id": 25881}, {
  "name": "UNION LIQUID FUND GROWTH",
  "id": 27616
}, {
  "name": "FRANKLIN INDIA LIQUID FUND - INSTITUTIONAL PLAN -GROWTH",
  "id": 27932
}, {
  "name": "HDFC ULTRA SHORT TERM FUND - REGULAR PLAN GROWTH",
  "id": 28378
}, {
  "name": "NIPPON INDIA OVERNIGHT FUND - GROWTH PLAN",
  "id": 29965
}, {
  "name": "UTI GILT FUND-DISCONTINUED - LONG TERM PLAN - PF PLAN - ( GROWTH OPTION )-GROWTH",
  "id": 30856
}, {
  "name": "DSP OVERNIGHT FUND - REGULAR PLAN - GROWTH",
  "id": 30952
}, {
  "name": "AXIS MONEY MARKET FUND REGULAR GROWTH",
  "id": 32974
}, {
  "name": "MAHINDRA MANULIFE ULTRA SHORT DURATION FUND- REGULAR - GROWTH",
  "id": 33393
}, {
  "name": "MIRAE ASSET OVERNIGHT FUND - REGULAR GROWTH PLAN",
  "id": 33460
}, {
  "name": "INVESCO INDIA OVERNIGHT FUND-REGULAR -GROWTH",
  "id": 36687
}, {
  "name": "AXIS ALL SEASONS DEBT FUND OF FUNDS REGULAR GROWTH",
  "id": 36710
}, {
  "name": "HSBC ULTRA SHORT DURATION FUND GROWTH",
  "id": 36742
}, {
  "name": "SBI FLOATING RATE DEBT FUND REGULAR PLAN GROWTH",
  "id": 39554
}, {
  "name": "ICICI PRUDENTIAL LIQUID FUND - GROWTH",
  "id": 49124
}, {
  "name": "SBI RETIREMENT BENEFIT FUND CONSERVATIVE HYBRID PLAN - REGULAR PLAN GROWTH",
  "id": 50051
}, {
  "name": "TATA FLOATING RATE FUND-REGULAR PLAN-GROWTH",
  "id": 89019
}, {
  "name": "TATA CORPORATE BOND FUND-REGULAR PLAN-GROWTH",
  "id": 90846
}, {
  "name": "TRUSTMF OVERNIGHT FUND - REGULAR PLAN - GROWTH",
  "id": 93578
}, {
  "name": "ADITYA BIRLA SUN LIFE ACTIVE DEBT MULTI-MANAGER FOF SCHEME - GROWTH",
  "id": 94025
}, {
  "name": "BARODA BNP PARIBAS OVERNIGHT FUND REGULAR PLAN GROWTH",
  "id": 95174
}, {"name": "ICICI PRUDENTIAL OVERNIGHT FUND GROWTH", "id": 96650}, {
  "name": "HSBC LOW DURATION FUND - GROWTH",
  "id": 99030
}, {
  "name": "QUANT GILT FUND-REGULAR PLAN - GROWTH",
  "id": 99719
}, {"name": "BANDHAN CORE EQUITY FUND-REGULAR PLAN-GROWTH", "id": 100273}, {
  "name": "HSBC FLEXI CAP FUND - GROWTH",
  "id": 100433
}, {"name": "HSBC VALUE FUND - GROWTH", "id": 100469}, {
  "name": "HSBC NIFTY NEXT 50 INDEX FUND - GROWTH",
  "id": 100533
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY 50 EQUAL WEIGHT INDEX FUND -NORMAL PLAN-GROWTH",
  "id": 100763
}, {"name": "HDFC FLEXICAP FUND - GROWTH OPTION", "id": 100804}, {
  "name": "HDFC ELSS TAX SAVER - GROWTH OPTION",
  "id": 100809
}, {
  "name": "HDFC TOP 100 FUND - REGULAR PLAN - GROWTH",
  "id": 100814
}, {
  "name": "HDFC BANKING AND FINANCIAL SERVICES FUND REGULAR GROWTH",
  "id": 100875
}, {
  "name": "HDFC MID-CAP OPPORTUNITIES FUND - GROWTH OPTION",
  "id": 100900
}, {
  "name": "HDFC LARGE AND MID CAP FUND - REGULAR PLAN - GROWTH",
  "id": 100912
}, {
  "name": "HDFC RETIREMENT SAVINGS FUND - HYBRID EQUITY PLAN - REGULAR - GROWTH",
  "id": 100940
}, {
  "name": "HDFC NIFTY50 EQUAL WEIGHT INDEX FUND REGULAR PLAN GROWTH",
  "id": 100956
}, {
  "name": "ICICI PRUDENTIAL BALANCED ADVANTAGE FUND - GROWTH",
  "id": 101089
}, {
  "name": "ICICI PRUDENTIAL RETIREMENT FUND HYBRID AGGRESSIVE PLAN GROWTH",
  "id": 101139
}, {
  "name": "ICICI PRUDENTIAL FLEXICAP FUND GROWTH",
  "id": 101166
}, {
  "name": "ICICI PRUDENTIAL NIFTY ALPHA LOW VOLATILITY 30 ETF FOF GROWTH",
  "id": 101170
}, {
  "name": "ICICI PRUDENTIAL NIFTY NEXT 50 INDEX FUND - GROWTH",
  "id": 101364
}, {
  "name": "ICICI PRUDENTIAL PASSIVE STRATEGY FUND (FOF) - GROWTH",
  "id": 101382
}, {
  "name": "ICICI PRUDENTIAL ASSET ALLOCATOR FUND (FOF) - GROWTH",
  "id": 101390
}, {
  "name": "ICICI PRUDENTIAL EQUITY ARBITRAGE FUND - GROWTH",
  "id": 101400
}, {
  "name": "ICICI PRUDENTIAL FOCUSED EQUITY FUND - GROWTH",
  "id": 101415
}, {
  "name": "NIPPON INDIA BANKING AND FINANCIAL SERVICES FUND - GROWTH PLAN- GROWTH OPTION",
  "id": 101431
}, {
  "name": "NIPPON INDIA GROWTH FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 101458
}, {
  "name": "NIPPON INDIA NIFTY NEXT 50 JUNIOR BEES FOF - GROWTH PLAN",
  "id": 101600
}, {"name": "NIPPON INDIA ARBITRAGE FUND - GROWTH", "id": 101629}, {
  "name": "SBI LARGE AND MIDCAP FUND REGULAR GROWTH",
  "id": 101681
}, {
  "name": "SBI HEALTHCARE OPPORTUNITIES FUND REGULAR GROWTH",
  "id": 101693
}, {"name": "SBI MAGNUM MIDCAP FUND - GROWTH", "id": 101705}, {
  "name": "SBI MULTI ASSET ALLOCATION FUND REGULAR GROWTH",
  "id": 101714
}, {
  "name": "SBI ARBITRAGE OPPORTUNITIES FUND GROWTH",
  "id": 101724
}, {
  "name": "SBI BANKING AND FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
  "id": 101768
}, {
  "name": "SBI EQUITY SAVINGS FUND - REGULAR PLAN - GROWTH",
  "id": 101769
}, {
  "name": "SBI RETIREMENT BENEFIT FUND AGGRESSIVE PLAN - REGULAR PLAN GROWTH",
  "id": 101790
}, {
  "name": "SBI RETIREMENT BENEFIT FUND AGGRESSIVE HYBRID PLAN - REGULAR PLAN GROWTH",
  "id": 101792
}, {
  "name": "SBI NIFTY NEXT 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 101796
}, {
  "name": "SBI BALANCED ADVANTAGE FUND - REGULAR PLAN - GROWTH",
  "id": 101800
}, {
  "name": "SBI CONSUMPTION OPPORTUNITIES FUND REGULAR GROWTH",
  "id": 101952
}, {"name": "SBI INFRASTRUCTURE FUND REGULAR PLAN - GROWTH", "id": 101955}, {
  "name": "SBI GOLD FUND - GROWTH",
  "id": 101958
}, {
  "name": "INVESCO INDIA FINANCIAL SERVICES FUND - GROWTH",
  "id": 102008
}, {
  "name": "INVESCO INDIA LARGECAP FUND - GROWTH",
  "id": 102011
}, {
  "name": "INVESCO INDIA INFRASTRUCTURE FUND - GROWTH",
  "id": 102023
}, {
  "name": "INVESCO INDIA FOCUSED FUND REGULAR PLAN GROWTH",
  "id": 102046
}, {
  "name": "INVESCO INDIA SMALL CAP FUND - REGULAR GROWTH",
  "id": 102067
}, {
  "name": "UTI EQUITY SAVINGS FUND - REGULAR GROWTH PLAN - GROWTH",
  "id": 102116
}, {
  "name": "UTI GOLD ETF FUND OF FUND- REGULAR PLAN -GROWTH",
  "id": 102135
}, {
  "name": "UTI AGGRESSIVE HYBRID FUND - GROWTH",
  "id": 102138
}, {
  "name": "UTI LARGE & MID CAP FUND - REGULAR PLAN-GROWTH",
  "id": 102176
}, {
  "name": "UTI NIFTY200 MOMENTUM 30 INDEX FUND - REGULAR PLAN GROWTH",
  "id": 102207
}, {
  "name": "UTI NIFTY NEXT 50 INDEX FUND - REGULAR GROWTH PLAN",
  "id": 102209
}, {
  "name": "UTI ARBITRAGE FUND - GROWTH PLAN",
  "id": 102218
}, {
  "name": "UTI MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 102230
}, {
  "name": "BANK OF INDIA MANUFACTURING AND INFRASTRUCTURE FUND - REGULAR - GROWTH",
  "id": 102272
}, {
  "name": "BANK OF INDIA FLEXI CAP FUND REGULAR PLAN - GROWTH",
  "id": 102288
}, {
  "name": "BANK OF INDIA SMALL CAP FUND-REGULAR PLAN-GROWTH",
  "id": 102294
}, {
  "name": "DSP NIFTY 50 EQUAL WEIGHT INDEX FUND - REG - GROWTH",
  "id": 102336
}, {
  "name": "DSP NIFTY NEXT 50 INDEX FUND - REGULAR - GROWTH",
  "id": 102355
}, {"name": "DSP ELSS TAX SAVER FUND - GROWTH", "id": 102464}, {
  "name": "KOTAK EQUITY OPPORTUNITIES FUND - GROWTH",
  "id": 102492
}, {"name": "KOTAK EMERGING EQUITY FUND - GROWTH", "id": 102497}, {
  "name": "KOTAK ELSS TAX SAVER FUND - GROWTH",
  "id": 102504
}, {
  "name": "MIRAE ASSET BANKING AND FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
  "id": 102639
}, {
  "name": "MIRAE ASSET EQUITY ALLOCATOR FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 102645
}, {
  "name": "MIRAE ASSET EQUITY SAVINGS FUND REGULAR GROWTH",
  "id": 102651
}, {
  "name": "MIRAE ASSET GREAT CONSUMER FUND - REGULAR PLAN GROWTH OPTION",
  "id": 102669
}, {
  "name": "FRANKLIN INDIA ELSS TAX SAVER FUND - GROWTH",
  "id": 102710
}, {
  "name": "FRANKLIN INDIA FOCUSED EQUITY FUND-GROWTH",
  "id": 102778
}, {"name": "FRANKLIN INDIA FLEXI CAP FUND - GROWTH", "id": 102859}, {
  "name": "TEMPLETON INDIA VALUE FUND - GROWTH",
  "id": 102862
}, {
  "name": "SUNDARAM CONSUMPTION FUND INSTITUTIONAL GROWTH",
  "id": 102876
}, {
  "name": "SUNDARAM FINANCIAL SERVICES OPPORTUNITIES INSTITUTIONAL PLAN GROWTH",
  "id": 102880
}, {
  "name": "SUNDARAM NIFTY 100 EQUAL WEIGHT FUND - REGULAR PLAN GROWTH",
  "id": 102949
}, {
  "name": "SUNDARAM BALANCED ADVANTAGE FUND - REGULAR PLAN GROWTH",
  "id": 102959
}, {
  "name": "MOTILAL OSWAL ELSS TAX SAVER FUND - REGULAR GROWTH",
  "id": 103018
}, {
  "name": "MOTILAL OSWAL NIFTY NEXT 50 INDEX FUND - REGULAR GROWTH",
  "id": 103031
}, {
  "name": "EDELWEISS ARBITRAGE FUND GROWTH OPTION",
  "id": 103057
}, {
  "name": "EDELWEISS EQUITY SAVINGS FUND - REGULAR PLAN - GROWTH",
  "id": 103077
}, {
  "name": "EDELWEISS BALANCED ADVANTAGE FUND - GROWTH OPTION",
  "id": 103080
}, {
  "name": "EDELWEISS LARGE CAP FUND - GROWTH OPTION",
  "id": 103083
}, {
  "name": "EDELWEISS DIVERSIFIED GROWTH EQUITY TOP 100 FUND-PLAN B- GROWTH",
  "id": 103086
}, {
  "name": "EDELWEISS DIVERSIFIED GROWTH EQUITY TOP 100 FUND-PLAN C- GROWTH",
  "id": 103089
}, {
  "name": "EDELWEISS FLEXI CAP FUND - REGULAR PLAN GROWTH",
  "id": 103101
}, {
  "name": "EDELWEISS MID CAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 103139
}, {
  "name": "EDELWEISS SMALL CAP FUND - REGULAR PLAN - GROWTH",
  "id": 103163
}, {
  "name": "360 ONE FOCUSED EQUITY FUND REGULAR PLAN- GROWTH",
  "id": 103377
}, {
  "name": "BARODA BNP PARIBAS BALANCED ADVANTAGE FUND - REGULAR GROWTH",
  "id": 103535
}, {
  "name": "BARODA BNP PARIBAS LARGE CAP FUND GROWTH",
  "id": 103568
}, {
  "name": "BARODA BNP PARIBAS AGGRESSIVE HYBRID FUND REGULAR GROWTH",
  "id": 103592
}, {"name": "JM VALUE FUND - GROWTH OPTION", "id": 103794}, {
  "name": "JM FOCUSED FUND - GROWTH OPTION",
  "id": 103801
}, {
  "name": "JM ELSS TAX SAVER FUND-REGULAR PLAN GROWTH",
  "id": 103899
}, {
  "name": "CANARA ROBECO FOCUSED EQUITY FUND REGULAR GROWTH",
  "id": 103992
}, {
  "name": "CANARA ROBECO SMALL CAP FUND REGULAR GROWTH",
  "id": 104018
}, {
  "name": "CANARA ROBECO VALUE FUND REGULAR GROWTH",
  "id": 104024
}, {
  "name": "CANARA ROBECO INFRASTRUCTURE REGULAR -GROWTH",
  "id": 104042
}, {
  "name": "CANARA ROBECO BLUECHIP EQUITY FUND REGULARGROWTH",
  "id": 104045
}, {
  "name": "MAHINDRA MANULIFE FOCUSED FUND - REGULAR PLAN - GROWTH",
  "id": 104181
}, {
  "name": "MAHINDRA MANULIFE AGGRESSIVE HYBRID FUND - REGULAR - GROWTH",
  "id": 104189
}, {"name": "UNION FLEXICAP FUND - GROWTH", "id": 104236}, {
  "name": "UNION ELSS TAX SAVER FUND - REGULAR PLAN GROWTH",
  "id": 104298
}, {
  "name": "TATA ARBITRAGE FUND REGULAR PLAN GROWTH",
  "id": 104506
}, {
  "name": "TATA BALANCED ADVANTAGE FUND REGULAR PLAN GROWTH",
  "id": 104519
}, {
  "name": "TATA INDIA PHARMA AND HEALTHCARE FUND REGULAR PLAN GROWTH",
  "id": 104628
}, {
  "name": "TATA SMALL CAP FUND REGULAR PLAN GROWTH",
  "id": 104686
}, {
  "name": "KOTAK NIFTY NEXT 50 INDEX FUND-REGULAR PLAN - GROWTH",
  "id": 104709
}, {"name": "HDFC ASSET ALLOCATOR FOF REGULAR GROWTH", "id": 104833}, {
  "name": "HDFC GOLD FUND",
  "id": 104834
}, {
  "name": "KOTAK EQUITY HYBRID FUND - REGULAR PLAN GROWTH",
  "id": 104940
}, {
  "name": "ICICI PRUDENTIAL EQUITY SAVINGS FUND CUMULATIVE",
  "id": 105942
}, {
  "name": "ICICI PRUDENTIAL CHILD CARE FUND (GIFT PLAN)",
  "id": 105966
}, {
  "name": "LIC NIFTY NEXT 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 107042
}, {
  "name": "HDFC DYNAMIC DEBT FUND - REGULAR PLAN- GROWTH",
  "id": 22
}, {
  "name": "HDFC MEDIUM TERM DEBT FUND - REGULAR PLAN - GROWTH",
  "id": 42
}, {
  "name": "HDFC GILT FUND - REGULAR PLAN- GROWTH",
  "id": 382
}, {"name": "HDFC MONEY MARKET FUND - REGULAR PLAN - GROWTH", "id": 419}, {
  "name": "HDFC LIQUID FUND - GROWTH",
  "id": 985
}, {
  "name": "HDFC INCOME FUND - GROWTH OPTION",
  "id": 1044
}, {
  "name": "ICICI PRUDENTIAL MULTI-ASSET FUND - INSTITUTIONAL GROWTH",
  "id": 2392
}, {
  "name": "ICICI PRUDENTIAL SMALLCAP FUND - INSTITUTIONAL GROWTH",
  "id": 2799
}, {
  "name": "NIPPON INDIA STRATEGIC DEBT FUND- GROWTH PLAN",
  "id": 2846
}, {
  "name": "NIPPON INDIA FLOATING RATE FUND - GROWTH PLAN",
  "id": 3163
}, {
  "name": "NIPPON INDIA INCOME FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 3204
}, {
  "name": "NIPPON INDIA LIQUID FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 3240
}, {
  "name": "NIPPON INDIA CREDIT RISK FUND - INSTITUTIONAL GROWTH",
  "id": 3310
}, {
  "name": "NIPPON INDIA SHORT TERM FUND-GROWTH PLAN - GROWTH OPTION",
  "id": 3333
}, {
  "name": "NIPPON INDIA BANKING AND PSU DEBT FUND - GROWTH PLAN-GROWTH OPTION",
  "id": 3371
}, {
  "name": "NIPPON INDIA LOW DURATION FUND - GROWTH PLAN GROWTH OPTION",
  "id": 3777
}, {
  "name": "INVESCO INDIA CREDIT RISK FUND - REGULAR PLAN GROWTH",
  "id": 4002
}, {
  "name": "INVESCO INDIA CORPORATE BOND FUND - GROWTH",
  "id": 4024
}, {
  "name": "INVESCO INDIA MONEY MARKET FUND - REGULAR GROWTH",
  "id": 4032
}, {
  "name": "INVESCO INDIA GILT FUND - GROWTH",
  "id": 4045
}, {
  "name": "INVESCO INDIA ULTRA SHORT DURATION FUND - GROWTH",
  "id": 4077
}, {
  "name": "INVESCO INDIA MONEY MARKET FUND - GROWTH",
  "id": 4151
}, {
  "name": "INVESCO INDIA LOW DURATION FUND - GROWTH",
  "id": 4242
}, {
  "name": "BANDHAN CORPORATE BOND FUND REGULAR PLAN-GROWTH",
  "id": 4678
}, {
  "name": "BANDHAN ARBITRAGE PLUS FUND - PLAN B - GROWTH",
  "id": 5031
}, {
  "name": "BANDHAN ALL SEASONS BOND FUND -REGULAR PLAN- GROWTH OPTION",
  "id": 5074
}, {
  "name": "BANDHAN LOW DURATION FUND-REGULAR PLAN-GROWTH",
  "id": 5077
}, {
  "name": "BANDHAN GOVERNMENT SECURITIES FUND- INVESTMENT PLAN-REGULAR PLAN-GROWTH",
  "id": 5159
}, {
  "name": "BANDHAN LIQUID FUND -REGULAR PLAN-GROWTH",
  "id": 5242
}, {
  "name": "DSP LOW DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 5620
}, {"name": "DSP STRATEGIC BOND FUND - REGULAR PLAN - GROWTH", "id": 5709}, {
  "name": "DSP BOND FUND - GROWTH",
  "id": 5726
}, {"name": "DSP LIQUIDITY FUND - GROWTH", "id": 5822}, {
  "name": "DSP BANKING AND PSU DEBT FUND - REG - GROWTH",
  "id": 5840
}, {"name": "DSP SAVINGS FUND - GROWTH", "id": 5862}, {
  "name": "DSP SHORT TERM FUND - GROWTH",
  "id": 5874
}, {"name": "DSP REGULAR SAVINGS FUND - GROWTH", "id": 5890}, {
  "name": "DSP ULTRA SHORT FUND - REGULAR PLAN - GROWTH",
  "id": 5896
}, {
  "name": "MIRAE ASSET LOW DURATION FUND - REGULAR SAVINGS PLAN - GROWTH PLAN",
  "id": 6012
}, {
  "name": "SUNDARAM BANKING AND PSU FUND REGULAR PLAN GROWTH",
  "id": 6356
}, {
  "name": "SUNDARAM CORPORATE BOND FUND REGULAR GROWTH",
  "id": 6364
}, {
  "name": "SUNDARAM CONSERVATIVE HYBRID FUND REGULAR GROWTH",
  "id": 6439
}, {
  "name": "AXIS DYNAMIC BOND FUND - REGULAR GROWTH - GROWTH",
  "id": 7104
}, {
  "name": "AXIS CREDIT RISK FUND - REGULAR PLAN GROWTH",
  "id": 7220
}, {
  "name": "PGIM INDIA CORPORATE BOND FUND REGULAR PLAN - GROWTH",
  "id": 7542
}, {
  "name": "PGIM INDIA GILT FUND - REGULAR PLAN - GROWTH",
  "id": 7581
}, {
  "name": "PGIM INDIA LIQUID FUND REGULAR PLAN - GROWTH",
  "id": 7633
}, {
  "name": "PGIM INDIA ULTRA SHORT DURATION FUND REGULAR PLAN - GROWTH",
  "id": 7651
}, {
  "name": "PGIM INDIA DYNAMIC BOND FUND - REGULAR PLAN - GROWTH",
  "id": 7803
}, {
  "name": "LIC MF MEDIUM TO LONG DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 8293
}, {
  "name": "LIC MF LIQUID FUND-GROWTH-GROWTH",
  "id": 8405
}, {
  "name": "LIC MF BANKING AND PSU FUND-REGULAR PLAN-GROWTH-GROWTH",
  "id": 8414
}, {
  "name": "CANARA ROBECO SHORT DURATION FUND REGULAR GROWTH",
  "id": 8507
}, {"name": "SBI CREDIT RISK FUND REGULAR GROWTH", "id": 8928}, {
  "name": "SBI LIQUID FUND REGULAR GROWTH",
  "id": 9103
}, {
  "name": "SBI MAGNUM ULTRA SHORT DURATION FUND REGULAR GROWTH",
  "id": 9111
}, {
  "name": "TATA GILT SECURITIES FUND REGULAR PLAN GROWTH",
  "id": 9381
}, {"name": "TATA LIQUID FUND REGULAR PLAN GROWTH", "id": 9497}, {
  "name": "BARODA BNP PARIBAS GILT FUND REGULAR GROWTH",
  "id": 10101
}, {
  "name": "ADITYA BIRLA SUN LIFE INCOME FUND - GROWTH-REGULAR PLAN",
  "id": 10623
}, {
  "name": "ADITYA BIRLA SUN LIFE REGULAR SAVINGS FUND-GROWTH",
  "id": 10659
}, {
  "name": "ADITYA BIRLA SUN LIFE REGULAR SAVINGS FUND-MONTHLY PAYMENT",
  "id": 10665
}, {
  "name": "ADITYA BIRLA SUN LIFE CREDIT RISK FUND - GROWTH REGULAR",
  "id": 10786
}, {
  "name": "ADITYA BIRLA SUN LIFE FINANCIAL PLANNING FOF - CONSERVATIVE PLAN-GROWTH",
  "id": 10792
}, {
  "name": "ADITYA BIRLA SUN LIFE FINANCIAL PLANNING FOF - MODERATE PLAN - GROWTH",
  "id": 10798
}, {
  "name": "ADITYA BIRLA SUN LIFE FINANCIAL PLANNING FOF - AGGRESSIVE PLAN-GROWTH",
  "id": 10804
}, {
  "name": "UTI ULTRA SHORT DURATION FUND -REGULAR PLAN-GROWTH",
  "id": 11834
}, {
  "name": "UTI SHORT TERM INCOME FUND-DISCONTINUED REGULAR REGULAR GROWTH",
  "id": 12038
}, {
  "name": "CANARA ROBECO SAVINGS FUND REGULAR GROWTH",
  "id": 16551
}, {
  "name": "CANARA ROBECO GILT FUND REGULAR GROWTH",
  "id": 16557
}, {
  "name": "CANARA ROBECO CORPORATE BOND FUND REGULAR GROWTH",
  "id": 16581
}, {
  "name": "ICICI PRUDENTIAL ULTRA SHORT TERM FUND - GROWTH",
  "id": 17096
}, {
  "name": "QUANTUM GOLD SAVINGS FUND - REGULAR PLAN GROWTH OPTION",
  "id": 17509
}, {
  "name": "FRANKLIN INDIA FLOATING RATE FUND - GROWTH",
  "id": 17931
}, {
  "name": "FRANKLIN INDIA LIQUID FUND - SUPER INSTITUTIONAL PLAN-GROWTH",
  "id": 17934
}, {
  "name": "FRANKLIN INDIA CORPORATE DEBT FUND- PLAN A - GROWTH",
  "id": 18074
}, {
  "name": "FRANKLIN INDIA CORPORATE DEBT FUND- PLAN B - GROWTH",
  "id": 18080
}, {
  "name": "FRANKLIN INDIA DEBT HYBRID FUND - PLAN B - GROWTH",
  "id": 18122
}, {
  "name": "FRANKLIN INDIA DEBT HYBRID FUND - PLAN A - GROWTH",
  "id": 18144
}, {
  "name": "FRANKLIN INDIA MONEY MARKET FUND-GROWTH",
  "id": 18188
}, {
  "name": "FRANKLIN INDIA DYNAMIC ASSET ALLOCATION FUND OF FUNDS  - GROWTH",
  "id": 18344
}, {
  "name": "KOTAK DYNAMIC BOND FUND - REGULAR PLAN - GROWTH",
  "id": 18729
}, {"name": "KOTAK BANKING AND PSU FUND - GROWTH", "id": 18818}, {
  "name": "KOTAK GILT FUND - REGULAR PLAN - GROWTH",
  "id": 18820
}, {"name": "KOTAK BOND FUND - GROWTH", "id": 18831}, {
  "name": "KOTAK BOND SHORT TERM FUND - GROWTH",
  "id": 18834
}, {
  "name": "KOTAK GILT FUND - PROVIDEND FUND AND TRUST - GROWTH",
  "id": 18836
}, {
  "name": "KOTAK LIQUID FUND - REGULAR PLAN - GROWTH",
  "id": 18970
}, {"name": "HSBC LOW DURATION FUND - REGULAR - GROWTH", "id": 19849}, {
  "name": "HSBC CONSERVATIVE HYBRID FUND -GROWTH",
  "id": 19866
}, {
  "name": "EDELWEISS VALUE OPPORTUNITIES FUND - PLAN- C-GROWTH PLAN",
  "id": 20242
}, {"name": "EDELWEISS LIQUID FUND-INSTITUTIONAL-GROWTH", "id": 20270}, {
  "name": "EDELWEISS LIQUID FUND-RETAIL-GROWTH",
  "id": 20274
}, {
  "name": "EDELWEISS ULTRASHORT TERM BOND FUND-RETAIL-GROWTH",
  "id": 20290
}, {
  "name": "EDELWIESS SHORT TERM INCOME FUND INSTITUTIONAL GROWTH",
  "id": 20296
}, {
  "name": "EDELWIESS SHORT TERM INCOME FUND RETAIL GROWTH",
  "id": 20301
}, {
  "name": "EDELWEISS GOVERNMENT SECURITIES FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 20339
}, {"name": "TAURUS DYNAMIC INCOME FUND GROWTH", "id": 20724}, {
  "name": "JM DYNAMIC BOND FUND - GROWTH OPTION",
  "id": 21144
}, {"name": "JM LIQUID FUND - GROWTH OPTION", "id": 21345}, {
  "name": "MIRAE ASSET SHORT DURATION FUND REGULAR - GROWTH",
  "id": 23370
}, {
  "name": "BANDHAN ULTRA SHORT TERM FUND REGULAR PLAN-GROWTH",
  "id": 25614
}, {
  "name": "AXIS ULTRA SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 27783
}, {"name": "SUNDARAM MONEY MARKET FUND REGULAR GROWTH", "id": 28854}, {
  "name": "UTI FLOATER FUND-REGULAR GROWTH PLAN",
  "id": 29011
}, {
  "name": "ADITYA BIRLA SUN LIFE OVERNIGHT FUND - REGULAR PLAN - GROWTH",
  "id": 29870
}, {
  "name": "KOTAK OVERNIGHT FUND - REGULAR PLAN - GROWTH",
  "id": 30291
}, {
  "name": "SBI CORPORATE BOND FUND - REGULAR PLAN GROWTH",
  "id": 30386
}, {
  "name": "BANDHAN OVERNIGHT FUND REGULAR PLAN-GROWTH",
  "id": 30397
}, {
  "name": "ICICI PRUDENTIAL RETIREMENT FUND HYBRID CONSERVATIVE PLAN GROWTH",
  "id": 30685
}, {
  "name": "ICICI PRUDENTIAL RETIREMENT FUND PURE DEBT PLAN GROWTH",
  "id": 30689
}, {"name": "SUNDARAM OVERNIGHT FUND REGULAR GROWTH", "id": 31943}, {
  "name": "TATA OVERNIGHT FUND REGULAR PLAN GROWTH",
  "id": 32046
}, {"name": "UNION OVERNIGHT FUND REGULAR PLAN GROWTH OPTION", "id": 32085}, {
  "name": "HSBC OVERNIGHT FUND - GROWTH",
  "id": 32556
}, {
  "name": "CANARA ROBECO OVERNIGHT FUND REGULAR GROWTH",
  "id": 32931
}, {
  "name": "MAHINDRA MANULIFE OVERNIGHT FUND - REGULAR - GROWTH",
  "id": 32939
}, {
  "name": "PGIM INDIA OVERNIGHT FUND - REGULAR PLAN - GROWTH",
  "id": 33050
}, {
  "name": "EDELWEISS OVERNIGHT FUND - REGULAR PLAN - GROWTH",
  "id": 33532
}, {
  "name": "WHITEOAK CAPITAL ULTRA SHORT DURATION FUND REGULAR - GROWTH",
  "id": 35415
}, {
  "name": "PGIM INDIA MONEY MARKET FUND - REGULAR PLAN - GROWTH",
  "id": 37249
}, {
  "name": "TRUSTMF BANKING & PSU FUND - REGULAR PLAN - GROWTH",
  "id": 50018
}, {
  "name": "SBI RETIREMENT BENEFIT FUND CONSERVATIVE PLAN - REGULAR PLAN GROWTH",
  "id": 50053
}, {
  "name": "BANDHAN FLOATING RATE FUND REGULAR PLAN-GROWTH",
  "id": 50250
}, {
  "name": "ITI DYNAMIC BOND FUND - REGULAR PLAN - GROWTH",
  "id": 89063
}, {
  "name": "TRUSTMF SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 89670
}, {
  "name": "SUNDARAM LIQUID FUND  - REGULAR PLAN  GROWTH",
  "id": 93362
}, {
  "name": "ADITYA BIRLA SUN LIFE ASSET ALLOCATOR FOF SCHEME - GROWTH",
  "id": 94009
}, {
  "name": "BARODA BNP PARIBAS CONSERVATIVE HYBRID FUND GROWTH",
  "id": 94993
}, {
  "name": "BARODA BNP PARIBAS DYNAMIC BOND FUND - GROWTH",
  "id": 95025
}, {
  "name": "BARODA BNP PARIBAS LIQUID FUND REGULAR GROWTH",
  "id": 95085
}, {
  "name": "BARODA BNP PARIBAS LOW DURATION FUND - GROWTH",
  "id": 95101
}, {
  "name": "BARODA BNP PARIBAS SHORT DURATION FUND REGULAR GROWTH",
  "id": 95204
}, {
  "name": "TRUSTMF MONEY  MARKET FUND-REGULAR PLAN-GROWTH",
  "id": 96643
}, {
  "name": "CANARA ROBECO BANKING AND PSU DEBT FUND REGULAR PLAN GROWTH",
  "id": 96836
}, {"name": "HSBC MONEY MARKET FUND - GROWTH", "id": 98800}, {
  "name": "HSBC CREDIT RISK FUND - GROWTH",
  "id": 98872
}, {"name": "HSBC MEDIUM DURATION FUND GROWTH", "id": 99174}, {
  "name": "HSBC DYNAMIC BOND FUND - GROWTH",
  "id": 99216
}, {
  "name": "HSBC MANAGED SOLUTIONS INDIA GROWTH FUND GROWTH",
  "id": 100030
}, {
  "name": "BANDHAN SMALL CAP FUND REGULAR PLAN-GROWTH",
  "id": 100197
}, {
  "name": "BANDHAN FLEXI CAP FUND - REGULAR PLAN GROWTH",
  "id": 100275
}, {
  "name": "BANDHAN FOCUSED EQUITY FUND-REGULAR PLAN-GROWTH",
  "id": 100277
}, {
  "name": "BANDHAN LARGE CAP FUND-REGULAR PLAN-GROWTH",
  "id": 100279
}, {
  "name": "BANDHAN ARBITRAGE FUND-REGULAR PLAN- GROWTH",
  "id": 100281
}, {
  "name": "BANDHAN STERLING VALUE FUND-REGULAR PLAN-GROWTH",
  "id": 100283
}, {
  "name": "BANDHAN EQUITY SAVINGS FUND-REGULAR PLAN-GROWTH",
  "id": 100285
}, {
  "name": "BANDHAN ELSS TAX SAVER FUND-REGULAR PLAN-GROWTH",
  "id": 100287
}, {"name": "BANDHAN NIFTY 50 INDEX FUND-REGULAR PLAN-GROWTH", "id": 100304}, {
  "name": "HSBC MIDCAP FUND GROWTH",
  "id": 100405
}, {"name": "HSBC INFRASTRUCTURE FUND - GROWTH", "id": 100426}, {
  "name": "HSBC LARGE CAP FUND - GROWTH",
  "id": 100430
}, {"name": "HSBC TAX SAVER EQUITY FUND - GROWTH", "id": 100435}, {
  "name": "HSBC AGGRESSIVE HYBRID FUND - GROWTH",
  "id": 100454
}, {"name": "HSBC BALANCED ADVANTAGE FUND - GROWTH", "id": 100458}, {
  "name": "HSBC ELSS TAX SAVER FUND - GROWTH",
  "id": 100474
}, {"name": "HSBC ARBITRAGE FUND - GROWTH", "id": 100491}, {
  "name": "HSBC SMALL CAP FUND GROWTH",
  "id": 100511
}, {
  "name": "HSBC LARGE & MID CAP FUND-REGULAR PLAN GROWTH",
  "id": 100517
}, {
  "name": "HSBC NIFTY 50 INDEX FUND  - GROWTH",
  "id": 100525
}, {
  "name": "ADITYA BIRLA SUN LIFE BANKING AND FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
  "id": 100552
}, {
  "name": "ADITYA BIRLA SUN LIFE FRONTLINE EQUITY FUND - GROWTH",
  "id": 100561
}, {
  "name": "ADITYA BIRLA SUN LIFE ARBITRAGE FUND - GROWTH",
  "id": 100570
}, {
  "name": "ADITYA BIRLA SUN LIFE GENNEXT FUND-GROWTH",
  "id": 100582
}, {
  "name": "ADITYA BIRLA SUN LIFE BALANCED ADVANTAGE FUND - GROWTH OPTION",
  "id": 100597
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY MIDCAP 150 INDEX FUND-NORMAL PLAN-GROWTH",
  "id": 100751
}, {
  "name": "HDFC ARBITRAGE FUND-WHOLESALE PLAN - REGULAR PLAN - GROWTH",
  "id": 100864
}, {
  "name": "HDFC EQUITY SAVINGS FUND- GROWTH",
  "id": 100950
}, {
  "name": "HDFC INDEX FUND - NIFTY 50 PLAN - REGULAR PLAN - GROWTH",
  "id": 100964
}, {
  "name": "HDFC MULTI-ASSET FUND - REGULAR PLAN - GROWTH",
  "id": 100974
}, {
  "name": "HDFC HYBRID EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 100978
}, {
  "name": "ICICI PRUDENTIAL BANKING AND FINANCIAL SERVICES FUND - GROWTH",
  "id": 101081
}, {
  "name": "ICICI PRUDENTIAL MIDCAP FUND - GROWTH",
  "id": 101092
}, {
  "name": "ICICI PRUDENTIAL NIFTY 100 LOW VOLATILITY 30 ETF FOF GROWTH",
  "id": 101162
}, {
  "name": "ICICI PRUDENTIAL REGULAR GOLD SAVINGS FUND (FOF) - GROWTH",
  "id": 101369
}, {
  "name": "ICICI PRUDENTIAL NIFTY 50 INDEX FUND - GROWTH",
  "id": 101405
}, {
  "name": "ICICI PRUDENTIAL SMALLCAP FUND - GROWTH",
  "id": 101411
}, {
  "name": "NIPPON INDIA VISION FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 101466
}, {
  "name": "NIPPON INDIA BALANCED ADVANTAGE FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 101485
}, {
  "name": "NIPPON INDIA PHARMA FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 101501
}, {
  "name": "NIPPON INDIA POWER AND INFRA FUND- GROWTH PLAN-GROWTH OPT",
  "id": 101509
}, {
  "name": "NIPPON INDIA FOCUSED EQUITY FUND - GROWTH PLAN",
  "id": 101520
}, {
  "name": "NIPPON INDIA EQUITY HYBRID FUND GROWTH PLAN",
  "id": 101523
}, {
  "name": "NIPPON INDIA VALUE FUND - GROWTH OPTION",
  "id": 101526
}, {
  "name": "NIPPON INDIA FLEXICAP FUND-REGULAR PLAN-GROWTH",
  "id": 101558
}, {
  "name": "NIPPON INDIA MULTI ASSET ALLOCATION FUND-REGULAR PLAN-GROWTH",
  "id": 101564
}, {
  "name": "NIPPON INDIA NIFTY MIDCAP 150 INDEX FUND REGULAR PLAN GROWTH",
  "id": 101576
}, {
  "name": "NIPPON INDIA PASSIVE FLEXICAP FOF  PLAN GROWTH",
  "id": 101594
}, {
  "name": "NIPPON INDIA RETIREMENT FUND-WEALTH CREATION SCHEME-GROWTH PLAN GROWTH OPTION",
  "id": 101606
}, {"name": "SBI FLEXICAP FUND - GROWTH", "id": 101711}, {
  "name": "SBI BLUE CHIP FUND - GROWTH",
  "id": 101721
}, {"name": "SBI EQUITY HYBRID FUND REGULAR GROWTH", "id": 101727}, {
  "name": "SBI SMALL CAP FUND REGULAR GROWTH",
  "id": 101760
}, {
  "name": "INVESCO INDIA AGGRESSIVE HYBRID FUND - REGULAR PLAN GROWTH",
  "id": 101980
}, {
  "name": "INVESCO INDIA EQUITY SAVINGS FUND - REGULAR GROWTH",
  "id": 101995
}, {"name": "INVESCO INDIA CONTRA FUND - GROWTH", "id": 102014}, {
  "name": "INVESCO INDIA LARGE & MID CAP FUND - GROWTH",
  "id": 102020
}, {
  "name": "INVESCO INDIA MIDCAP FUND - GROWTH",
  "id": 102026
}, {
  "name": "INVESCO INDIA GOLD ETF FUND OF FUND - GROWTH",
  "id": 102037
}, {
  "name": "QUANTUM EQUITY FUND OF FUNDS - REGULAR PLAN GROWTH OPTION",
  "id": 102086
}, {
  "name": "QUANTUM MULTI ASSET FUND OF FUNDS - REGULAR PLAN GROWTH OPTION",
  "id": 102087
}, {
  "name": "QUANTUM ELSS TAX SAVER FUND - REGULAR PLAN GROWTH OPTION",
  "id": 102091
}, {"name": "UTI FOCUSED FUND - REGULAR PLAN GROWTH", "id": 102130}, {
  "name": "UTI LARGE CAP FUND- REGULAR PLAN- ROWTH",
  "id": 102164
}, {"name": "UTI VALUE FUND -REGULAR PLAN-GROWTH", "id": 102170}, {
  "name": "UTI HEALTHCARE FUND - GROWTH PLAN",
  "id": 102173
}, {"name": "UTI NIFTY 50 INDEX FUND - GROWTH", "id": 102205}, {
  "name": "UTI SMALL CAP FUND - REGULAR GROWTH PLAN",
  "id": 102224
}, {
  "name": "BANK OF INDIA LARGE AND MID CAP EQUITY FUND REGULAR PLAN - GROWTH",
  "id": 102267
}, {"name": "DSP ARBITRAGE FUND - REG - GROWTH", "id": 102340}, {
  "name": "DSP HEALTHCARE FUND - REGULAR - GROWTH",
  "id": 102347
}, {"name": "DSP NIFTY 50 INDEX FUND - REGULAR - GROWTH", "id": 102351}, {
  "name": "DSP EQUITY AND BOND FUND - GROWTH",
  "id": 102459
}, {
  "name": "DSP INDIA T.I.G.E.R. FUND - REGULAR PLAN - GROWTH",
  "id": 102466
}, {
  "name": "DSP TOP 100 EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 102468
}, {
  "name": "DSP FLEXI CAP FUND - REGULAR PLAN - GROWTH",
  "id": 102471
}, {
  "name": "DSP EQUITY OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 102486
}, {"name": "KOTAK SMALL CAP FUND - GROWTH", "id": 102491}, {
  "name": "KOTAK INDIA EQ CONTRA FUND - GROWTH",
  "id": 102498
}, {"name": "KOTAK BLUECHIP FUND - GROWTH", "id": 102501}, {
  "name": "KOTAK FLEXICAP FUND - GROWTH",
  "id": 102506
}, {
  "name": "KOTAK BALANCED ADVANTAGE FUND -REGULAR PLAN - GROWTH",
  "id": 102584
}, {
  "name": "KOTAK FOCUSED EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 102592
}, {
  "name": "MIRAE ASSET ARBITRAGE FUND - REGULAR PLAN - GROWTH",
  "id": 102627
}, {
  "name": "MIRAE ASSET HEALTHCARE FUND - REGULAR PLAN - GROWTH",
  "id": 102684
}, {
  "name": "MIRAE ASSET MIDCAP FUND - REGULAR PLAN - GROWTH",
  "id": 102690
}, {
  "name": "MIRAE ASSET AGGRESSIVE HYBRID FUND - REGULAR PLAN - GROWTH",
  "id": 102699
}, {
  "name": "MIRAE ASSET ELSS TAX SAVER FUND REGULAR PLAN GROWTH",
  "id": 102709
}, {
  "name": "FRANKLIN INDIA EQUITY HYBRID FUND - GROWTH",
  "id": 102761
}, {"name": "FRANKLIN INDIA SMALLER COMPANIES FUND-GROWTH", "id": 102772}, {
  "name": "FRANKLIN BUILD INDIA FUND-GROWTH",
  "id": 102784
}, {
  "name": "SUNDARAM MID CAP FUND REGULAR GROWTH",
  "id": 102867
}, {
  "name": "SUNDARAM LARGE CAP FUND - REGULAR PLAN GROWTH",
  "id": 102919
}, {
  "name": "SUNDARAM FOCUSED FUND - REGULAR  GROWTH",
  "id": 102931
}, {
  "name": "SUNDARAM EQUITY SAVINGS FUND  - REGULAR PLAN GROWTH",
  "id": 102952
}, {
  "name": "SUNDARAM AGGRESSIVE HYBRID FUND - REGULAR PLAN GROWTH",
  "id": 102963
}, {
  "name": "SUNDARAM ELSS TAX SAVER FUND - REGULAR  GROWTH",
  "id": 102970
}, {
  "name": "MOTILAL OSWAL FLEXI CAP FUND - REGULAR GROWTH",
  "id": 102977
}, {
  "name": "MOTILAL OSWAL NIFTY MIDCAP 150 INDEX FUND-REGULAR GROWTH",
  "id": 103025
}, {
  "name": "MOTILAL OSWAL NIFTY 500 INDEX FUND-REGULAR GROWTH",
  "id": 103033
}, {
  "name": "EDELWEISS LARGE AND MID CAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 103067
}, {
  "name": "EDELWEISS VALUE OPPORTUNITIES FUND - PLAN- B-GROWTH PLAN",
  "id": 103095
}, {
  "name": "EDELWEISS ELSS TAX SAVER FUND- GROWTH",
  "id": 103097
}, {
  "name": "EDELWEISS TAX ADVANTAGE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 103175
}, {
  "name": "AXIS BALANCED ADVANTAGE FUND REGULAR PLAN GROWTH",
  "id": 103208
}, {
  "name": "AXIS ARBITRAGE FUND - REGULAR PLAN - GROWTH",
  "id": 103214
}, {
  "name": "AXIS NIFTY 100 INDEX FUND REGULAR GROWTH",
  "id": 103302
}, {"name": "AXIS SMALL CAP FUND - REGULAR PLAN GROWTH", "id": 103340}, {
  "name": "AXIS VALUE FUND REGULAR GROWTH",
  "id": 103358
}, {"name": "TAURUS TAX SHIELD GROWTH", "id": 103402}, {
  "name": "TAURUS BANKING AND FINANCIAL SERVICES FUND - GROWTH",
  "id": 103411
}, {
  "name": "PGIM INDIA EQUITY SAVINGS FUND - REGULAR PLAN - GROWTH",
  "id": 103442
}, {
  "name": "PGIM INDIA MIDCAP OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 103447
}, {
  "name": "BARODA BNP PARIBAS ARBITRAGE FUND REGULAR GROWTH",
  "id": 103515
}, {
  "name": "BARODA BNP PARIBAS EQUITY SAVINGS FUND REGULAR GROWTH",
  "id": 103541
}, {
  "name": "BARODA BNP PARIBAS FOCUSED FUND REGULAR GROWTH",
  "id": 103547
}, {
  "name": "BARODA BNP PARIBAS INDIA CONSUMPTION FUND REGULAR GROWTH",
  "id": 103562
}, {
  "name": "BARODA BNP PARIBAS LARGE AND MID CAP FUND-REGULAR GROWTH",
  "id": 103574
}, {
  "name": "BARODA BNP PARIBAS MID CAP FUND GROWTH",
  "id": 103586
}, {
  "name": "BARODA BNP PARIBAS ELSS TAX SAVER FUND GROWTH",
  "id": 103596
}, {
  "name": "NAVI NIFTY 50 INDEX FUND REGULAR PLAN GROWTH",
  "id": 103652
}, {
  "name": "SHRIRAM FLEXI CAP FUND REGULAR - GROWTH",
  "id": 103759
}, {
  "name": "LIC MF FLEXI CAP FUND-REGULAR PLAN-GROWTH",
  "id": 103933
}, {
  "name": "LIC MF LARGE CAP FUND-REGULAR PLAN-GROWTH-GROWTH",
  "id": 103939
}, {
  "name": "CANARA ROBECO CONSUMER TRENDS FUND REGULAR GROWTH",
  "id": 104027
}, {
  "name": "CANARA ROBECO FLEXI CAP FUND REGULAR GROWTH",
  "id": 104030
}, {
  "name": "CANARA ROBECO ELSS TAX SAVER FUND REGULAR - GROWTH",
  "id": 104036
}, {
  "name": "CANARA ROBECO EQUITY HYBRID FUND REGULAR GROWTH",
  "id": 104039
}, {
  "name": "CANARA ROBECO CONSERVATIVE HYBRID FUND REGULAR GROWTH",
  "id": 104048
}, {
  "name": "MAHINDRA MANULIFE MID CAP FUND - REGULAR - GROWTH",
  "id": 104133
}, {
  "name": "MAHINDRA MANULIFE EQUITY SAVINGS FUND - REGULAR - GROWTH",
  "id": 104165
}, {
  "name": "MAHINDRA MANULIFE FLEXI CAP FUND - REGULAR PLAN - GROWTH",
  "id": 104173
}, {
  "name": "MAHINDRA MANULIFE ELSS TAX SAVER FUND - REGULAR - GROWTH",
  "id": 104194
}, {
  "name": "MAHINDRA MANULIFE LARGE & MID CAP FUND - REGULAR - GROWTH",
  "id": 104201
}, {
  "name": "MAHINDRA MANULIFE LARGE CAP FUND - REGULAR - GROWTH",
  "id": 104209
}, {
  "name": "MAHINDRA MANULIFE CONSUMPTION FUND - REGULAR - GROWTH",
  "id": 104217
}, {
  "name": "UNION FOCUSED FUND REGULAR  PLAN - GROWTH OPTION",
  "id": 104249
}, {
  "name": "UNION LARGECAP FUND REGULAR GROWTH",
  "id": 104251
}, {
  "name": "UNION BALANCED ADVANTAGE FUND REGULAR PLAN - GROWTH",
  "id": 104281
}, {
  "name": "ITI ELSS TAX SAVER FUND REGULAR PLAN GROWTH",
  "id": 104350
}, {"name": "ITI MID CAP FUND- REGULAR PLAN- GROWTH", "id": 104368}, {
  "name": "ITI VALUE FUND - REGULAR PLAN - GROWTH",
  "id": 104386
}, {
  "name": "QUANT INFRASTRUCTURE FUND REGULAR PLAN - GROWTH",
  "id": 104424
}, {
  "name": "TATA HYBRID EQUITY FUND REGULAR PLAN GROWTH",
  "id": 104477
}, {
  "name": "TATA LARGE AND MID CAP FUND REGULAR PLAN GROWTH",
  "id": 104482
}, {
  "name": "TATA EQUITY P/E FUND REGULAR PLAN GROWTH",
  "id": 104487
}, {
  "name": "TATA MID CAP GROWTH FUND REGULAR PLAN GROWTH",
  "id": 104490
}, {
  "name": "TATA EQUITY SAVINGS FUND REGULAR PLAN GROWTH",
  "id": 104493
}, {
  "name": "TATA INFRASTRUCTURE FUND REGULAR PLAN GROWTH",
  "id": 104498
}, {
  "name": "TATA LARGE CAP FUND REGULAR GROWTH",
  "id": 104499
}, {
  "name": "TATA BANKING AND FINANCIAL SERVICES FUND REGULAR PLAN GROWTH",
  "id": 104544
}, {
  "name": "TATA FOCUSED EQUITY FUND REGULAR PLAN GROWTH",
  "id": 104575
}, {
  "name": "TATA NIFTY 50 INDEX FUND  - REGULAR PLAN - GROWTH",
  "id": 104615
}, {
  "name": "TATA MULTI ASSET OPPORTUNITIES FUND REGULAR PLAN GROWTH",
  "id": 104639
}, {
  "name": "TATA FLEXI CAP FUND - REGULAR PLAN - GROWTH",
  "id": 104646
}, {
  "name": "TATA ELSS TAX SAVER FUND REGULAR PLAN GROWTH",
  "id": 104694
}, {
  "name": "TATA RETIREMENT SAVINGS FUND - MODERATE PLAN - GROWTH",
  "id": 104707
}, {
  "name": "TATA RETIREMENT SAVINGS FUND - PROGRESSIVE PLAN - GROWTH",
  "id": 104708
}, {
  "name": "BANDHAN ASSET ALLOCATION FUND OF FUND-AGGRESSIVE PLAN-REGULAR PLAN-GROWTH",
  "id": 104914
}, {
  "name": "BANDHAN ASSET ALLOCATION FUND OF FUND-CONSERVATIVE PLAN-REGULAR PLAN-GROWTH",
  "id": 104917
}, {
  "name": "BANK OF INDIA CREDIT RISK FUND - REGULAR PLAN - GROWTH",
  "id": 104922
}, {
  "name": "EDELWEISS CRISIL IBX 50:50 GILT PLUS SDL SHORT DURATION INDEX FUND- REGULAR PLAN GROWTH",
  "id": 105120
}, {
  "name": "DSP EQUITY SAVINGS FUND - REG - GROWTH",
  "id": 106020
}, {
  "name": "LIC MF FOCUSED FUND - REGULAR PLAN - GROWTH",
  "id": 106954
}, {
  "name": "LIC GOLD ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 106970
}, {
  "name": "LIC SMALL CAP FUND - REGULAR PLAN - GROWTH",
  "id": 107054
}, {
  "name": "HDFC LOW DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 60
}, {
  "name": "HDFC CREDIT RISK DEBT FUND - REGULAR - GROWTH",
  "id": 96
}, {
  "name": "HDFC OVERNIGHT FUND REGULAR PLAN - GROWTH",
  "id": 428
}, {
  "name": "ICICI PRUDENTIAL FLOATING INTEREST FUND - GROWTH",
  "id": 2318
}, {
  "name": "ICICI PRUDENTIAL CREDIT RISK FUND - GROWTH",
  "id": 2405
}, {
  "name": "NIPPON INDIA GILT SECURITIES FUND - GROWTH OPTION",
  "id": 3169
}, {
  "name": "NIPPON INDIA HYBRID BOND FUND - GROWTH PLAN GROWTH OPTION",
  "id": 3266
}, {
  "name": "INVESCO INDIA SHORT DURATION FUND - GROWTH",
  "id": 4094
}, {
  "name": "BANDHAN CONSERVATIVE HYBRID FUND -REGULAR PLAN - GROWTH OPTION",
  "id": 5055
}, {
  "name": "BANDHAN DYNAMIC BOND FUND-REGULAR PLAN-GROWTH",
  "id": 5140
}, {
  "name": "BANDHAN MONEY MANAGER FUND -REGULAR PLAN-GROWTH",
  "id": 5248
}, {
  "name": "BANDHAN BOND FUND - MEDIUM TERM PLAN-REGULAR PLAN-GROWTH",
  "id": 5260
}, {
  "name": "BANDHAN BOND FUND - LONG TERM PLAN-REGULAR PLAN-GROWTH",
  "id": 5269
}, {
  "name": "BANDHAN BOND FUND - SHORT TERM PLAN -REGULAR PLAN-GROWTH",
  "id": 5271
}, {
  "name": "PGIM INDIA SHORT MATURITY FUND - INSTITUTIONAL PLAN - GROWTH",
  "id": 7663
}, {
  "name": "PGIM INDIA SHORT MATURITY FUND - PREMIUM PLUS - GROWTH",
  "id": 7664
}, {"name": "LIC MF GILT FUND-REGULAR GROWTH-GROWTH", "id": 8353}, {
  "name": "LIC MF GILT FUND-PF GROWTH-GROWTH",
  "id": 8359
}, {
  "name": "LIC MF LOW DURATION FUND-REGULAR PLAN-GROWTH",
  "id": 8477
}, {"name": "SBI SAVINGS FUND - REGULAR PLAN - GROWTH", "id": 8918}, {
  "name": "SBI OVERNIGHT FUND REGULAR GROWTH",
  "id": 9089
}, {
  "name": "SBI BANKING AND PSU FUND REGULAR GROWTH",
  "id": 9169
}, {
  "name": "TATA SHORT TERM BOND FUND REGULAR PLAN GROWTH",
  "id": 9539
}, {
  "name": "TATA RETIREMENT SAVINGS FUND - CONSERVATIVE PLAN - GROWTH",
  "id": 9735
}, {
  "name": "BANK OF INDIA ULTRA SHORT DURATION FUND REGULAR PLAN - GROWTH",
  "id": 9868
}, {
  "name": "GROWW LIQUID FUND - EXISTING PLAN - GROWTH",
  "id": 10265
}, {
  "name": "ADITYA BIRLA SUN LIFE LOW DURATION FUND - GROWTH-REGULAR PLAN",
  "id": 11077
}, {"name": "ADITYA BIRLA SUN LIFE GOLD FUND - GROWTH", "id": 11090}, {
  "name": "UTI DYNAMIC BOND FUND - GROWTHPLAN",
  "id": 11806
}, {
  "name": "UTI MEDIUM DURATION FUND -REGULAR PLAN-GROWTH",
  "id": 12105
}, {
  "name": "CANARA ROBECO DYNAMIC BOND FUND REGULAR GROWTH",
  "id": 16535
}, {
  "name": "CANARA ROBECO INCOME FUND REGULAR GROWTH",
  "id": 16563
}, {
  "name": "CANARA ROBECO ULTRA SHORT TERM FUND REGULAR GROWTH",
  "id": 16593
}, {
  "name": "QUANTUM LIQUID FUND - REGULAR PLAN GROWTH OPTION",
  "id": 17501
}, {
  "name": "BANDHAN CREDIT RISK FUND-REGULAR PLAN -GROWTH",
  "id": 17659
}, {
  "name": "MIRAE ASSET DYNAMIC BOND FUND-REGULAR PLAN GROWTH",
  "id": 17918
}, {
  "name": "MAHINDRA MANULIFE LOW DURATION FUND - REGULAR - GROWTH",
  "id": 18705
}, {"name": "KOTAK MEDIUM TERM FUND - REGULAR PLAN - GROWTH", "id": 18745}, {
  "name": "KOTAK SAVINGS FUND - GROWTH",
  "id": 18892
}, {"name": "KOTAK GOLD FUND-GROWTH", "id": 18972}, {
  "name": "HSBC LIQUID  FUND GROWTH",
  "id": 19750
}, {
  "name": "EDELWEISS MONEY MARKET FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 20129
}, {
  "name": "EDELWEISS LIQUID FUND - REGULAR PLAN GROWTH - GROWTH",
  "id": 20396
}, {"name": "360 ONE LIQUID FUND REGULAR PLAN - GROWTH", "id": 20701}, {
  "name": "TAURUS SHORT TERM INCOME FUND GROWTH",
  "id": 20752
}, {
  "name": "JM MEDIUM TO LONG DURATION FUND - GROWTH OPTION",
  "id": 21175
}, {
  "name": "UNION CORPORATE BOND FUND REGULAR PLAN - GROWTH",
  "id": 27546
}, {
  "name": "NAVI REGULAR SAVING FUND - GROWTH",
  "id": 28625
}, {
  "name": "GROWW DYNAMIC BOND FUND REGULAR PLAN - GROWTH GROWTH",
  "id": 29521
}, {
  "name": "TATA ULTRA SHORT TERM FUND REGULAR PLAN GROWTH",
  "id": 30243
}, {
  "name": "DSP CORPORATE BOND FUND - REGULAR - GROWTH",
  "id": 30920
}, {
  "name": "NIPPON INDIA GILT SECURITIES FUND-P F OPT-DEFINED MATURITY DATE OPT",
  "id": 32013
}, {
  "name": "KOTAK FLOATING RATE FUND - REGULAR PLAN - GROWTH",
  "id": 32414
}, {
  "name": "FRANKLIN INDIA OVERNIGHT FUND - GROWTH",
  "id": 32426
}, {
  "name": "BARODA BNP PARIBAS MONEY MARKET FUND REGULAR PLAN GROWTH",
  "id": 32634
}, {
  "name": "LIC MF OVERNIGHT FUND - REGULAR PLAN-GROWTH",
  "id": 32884
}, {
  "name": "QUANT LIQUID FUND REGULAR PLAN - GROWTH",
  "id": 33648
}, {
  "name": "LIC ULTRA SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 35082
}, {
  "name": "JM OVERNIGHT FUND - REGULAR - GROWTH",
  "id": 35393
}, {
  "name": "WHITEOAK CAPITAL LIQUID FUND REGULAR - GROWTH",
  "id": 35493
}, {
  "name": "MIRAE ASSET BANKING AND PSU FUND - REGULAR PLAN - GROWTH",
  "id": 38225
}, {
  "name": "UNION MEDIUM DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 38684
}, {
  "name": "BARODA BNP PARIBAS BANKING AND PSU BOND FUND - REGULAR PLAN GROWTH",
  "id": 49228
}, {
  "name": "MIRAE ASSET CORPORATE BOND FUND - REGULAR PLAN - GROWTH",
  "id": 50393
}, {
  "name": "MAHINDRA MANULIFE SHORT DURATION FUND - REGULAR - GROWTH",
  "id": 50452
}, {
  "name": "TRUSTMF LIQUID FUND-REGULAR PLAN - GROWTH",
  "id": 50699
}, {
  "name": "INVESCO INDIA MEDIUM DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 89089
}, {
  "name": "MIRAE ASSET MONEY MARKET FUND REGULAR GROWTH",
  "id": 89747
}, {
  "name": "SUNDARAM ULTRA SHORT DURATION FUND  - REGULAR PLAN  GROWTH",
  "id": 93454
}, {
  "name": "BARODA BNP PARIBAS CORPORATE BOND FUND GROWTH",
  "id": 94968
}, {"name": "NJ OVERNIGHT FUND REGULAR PLAN - GROWTH", "id": 96435}, {
  "name": "UNION GILT FUND - REGULAR GROWTH",
  "id": 96603
}, {
  "name": "JM SHORT DURATION FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 96899
}, {
  "name": "SHRIRAM OVERNIGHT FUND REGULAR PLAN - GROWTH",
  "id": 96917
}, {
  "name": "MOTILAL OSWAL 5 YEAR G-SEC FUND OF FUND - REGULAR GROWTH",
  "id": 97017
}, {"name": "NAVI LIQUID FUND - REGULAR PLAN GROWTH", "id": 98004}, {
  "name": "HSBC SHORT DURATION FUND - GROWTH",
  "id": 98840
}, {
  "name": "HSBC BANKING AND PSU DEBT FUND - GROWTH",
  "id": 99114
}, {
  "name": "HSBC MANAGED SOLUTIONS INDIA CONSERVATIVE FUND GROWTH",
  "id": 100026
}, {
  "name": "HSBC MANAGED SOLUTIONS INDIA MODERATE FUND GROWTH",
  "id": 100033
}, {
  "name": "TRUSTMF CORPORATE BOND FUND-REGULAR PLAN-GROWTH",
  "id": 100083
}, {
  "name": "BANDHAN BALANCED ADVANTAGE FUND REGULAR PLAN - GROWTH",
  "id": 100179
}, {
  "name": "BANDHAN HYBRID EQUITY FUND REGULAR PLAN-GROWTH",
  "id": 100193
}, {
  "name": "BANDHAN INFRASTRUCTURE FUND-REGULAR PLAN-GROWTH",
  "id": 100289
}, {
  "name": "HSBC FOCUSED FUND REGULAR PLAN GROWTH",
  "id": 100463
}, {
  "name": "ADITYA BIRLA SUN LIFE MIDCAP FUND - GROWTH",
  "id": 100555
}, {
  "name": "ADITYA BIRLA SUN LIFE FLEXI CAP FUND - GROWTH",
  "id": 100558
}, {
  "name": "ADITYA BIRLA SUN LIFE FOCUSED FUND-GROWTH",
  "id": 100585
}, {
  "name": "ADITYA BIRLA SUN LIFE EQUITY HYBRID 95 FUND GROWTH",
  "id": 100588
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY 50 INDEX FUND - GROWTH",
  "id": 100634
}, {
  "name": "ADITYA BIRLA SUNLIFE LIFE PHARMA AND HEALTHCARE FUND NORMAL GROWTH",
  "id": 100715
}, {
  "name": "HDFC CAPITAL BUILDER VALUE FUND - REGULAR PLAN - GROWTH",
  "id": 100819
}, {
  "name": "HDFC SMALL CAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 100854
}, {
  "name": "HDFC INDEX FUND - BSE SENSEX PLAN - REGULAR PLAN - GROWTH",
  "id": 100966
}, {
  "name": "ICICI PRUDENTIAL ELSS TAX SAVER FUND - GROWTH",
  "id": 100981
}, {
  "name": "ICICI PRUDENTIAL BSE SENSEX INDEX FUND GROWTH",
  "id": 101116
}, {
  "name": "NIPPON INDIA NIFTY SMALLCAP 250 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 101582
}, {
  "name": "NIPPON INDIA GOLD SAVINGS FUND - GROWTH OPTION",
  "id": 101632
}, {
  "name": "NIPPON INDIA INDEX FUND - NIFTY 50 PLAN - GROWTH OPTION",
  "id": 101637
}, {
  "name": "NIPPON INDIA INDEX FUND - BSE SENSEX PLAN- GROWTH OPTION",
  "id": 101649
}, {
  "name": "NIPPON INDIA ELSS TAX SAVER FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 101654
}, {
  "name": "SBI NIFTY INDEX FUND- REGULAR PLAN - GROWTH",
  "id": 101697
}, {
  "name": "SBI FOCUSED EQUITY FUND REGULAR GROWTH",
  "id": 101702
}, {
  "name": "INVESCO INDIA BALANCED ADVANTAGE FUND - REGULAR PLAN GROWTH",
  "id": 102017
}, {
  "name": "INVESCO INDIA ELSS TAX SAVER FUND - GROWTH",
  "id": 102034
}, {
  "name": "QUANTUM LONG TERM EQUITY VALUE FUND - REGULAR PLAN GROWTH OPTION",
  "id": 102081
}, {
  "name": "UTI CHILDRENS EQUITY FUND-REGULAR GROWTH",
  "id": 102104
}, {
  "name": "UTI BANKING AND FINANCIAL SERVICES FUND - GROWTH PLAN",
  "id": 102140
}, {
  "name": "UTI ELSS TAX SAVER FUND -REGULAR PLAN-GROWTH",
  "id": 102149
}, {"name": "UTI INFRASTRUCTURE FUND - GROWTH PLAN", "id": 102155}, {
  "name": "UTI MID CAP FUND - GROWTH PLAN",
  "id": 102158
}, {
  "name": "BANK OF INDIA BLUECHIP FUND REGULAR PLAN - GROWTH",
  "id": 102264
}, {
  "name": "BANK OF INDIA BALANCED ADVANTAGE FUND - REGULAR PLAN GROWTH",
  "id": 102307
}, {
  "name": "DSP DYNAMIC ASSET ALLOCATION FUND - REGULAR - GROWTH",
  "id": 102313
}, {"name": "DSP SMALL CAP FUND - REGULAR PLAN - GROWTH", "id": 102469}, {
  "name": "DSP FOCUS FUND - GROWTH",
  "id": 102474
}, {
  "name": "MIRAE ASSET LARGE & MIDCAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 102657
}, {"name": "MIRAE ASSET LARGE CAP FUND - REGULAR - GROWTH", "id": 102675}, {
  "name": "FRANKLIN INDIA PRIMA FUND-GROWTH",
  "id": 102760
}, {
  "name": "FRANKLIN INDIA NSE NIFTY 50 INDEX FUND-GROWTH",
  "id": 102769
}, {
  "name": "FRANKLIN INDIA EQUITY SAVINGS FUND - GROWTH",
  "id": 102820
}, {
  "name": "FRANKLIN INDIA MULTI-ASSET SOLUTION FUND - GROWTH",
  "id": 102847
}, {
  "name": "FRANKLIN INDIA BLUECHIP FUND - GROWTH",
  "id": 102853
}, {
  "name": "FRANKLIN INDIA EQUITY ADVANTAGE FUND - GROWTH",
  "id": 102856
}, {
  "name": "SUNDARAM INFRASTRUCTURE ADVANTAGE FUND-REGULAR PLAN-GROWTH",
  "id": 102864
}, {
  "name": "SUNDARAM DIVERSIFIED EQUITY FUND REGULAR PLAN GROWTH",
  "id": 102870
}, {
  "name": "SUNDARAM SMALL CAP FUND REGULAR GROWTH",
  "id": 102872
}, {
  "name": "SUNDARAM CONSUMPTION FUND REGULAR GROWTH",
  "id": 102875
}, {
  "name": "SUNDARAM LARGE AND MID CAP FUND REGULAR GROWTH",
  "id": 102882
}, {
  "name": "MOTILAL OSWAL FOCUSED FUND - REGULAR GROWTH",
  "id": 102986
}, {
  "name": "MOTILAL OSWAL NIFTY BANK INDEX FUND-REGULAR GROWTH",
  "id": 102994
}, {
  "name": "MOTILAL OSWAL GOLD AND SILVER ETFS FUND OF FUNDS REGULAR GROWTH",
  "id": 103023
}, {
  "name": "MOTILAL OSWAL NIFTY 50 INDEX FUND - REGULAR GROWTH",
  "id": 103029
}, {
  "name": "MOTILAL OSWAL NIFTY SMALLCAP 250 INDEX FUND-REGULAR GROWTH",
  "id": 103043
}, {
  "name": "EDELWEISS MSCI INDIA DOMESTIC & WORLD HEALTHCARE 45 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 103181
}, {
  "name": "AXIS EQUITY SAVINGS FUND - REGULAR PLAN - GROWTH",
  "id": 103245
}, {
  "name": "AXIS MID CAP FUND - REGULAR GROWTH PLAN - GROWTH",
  "id": 103264
}, {
  "name": "AXIS FLEXI CAP FUND REGULAR PLAN GROWTH",
  "id": 103278
}, {
  "name": "AXIS GROWTH OPPORTUNITIES FUND - REGULAR PLAN GROWTH",
  "id": 103320
}, {
  "name": "TAURUS LARGECAP EQUITY FUND - REGULAR PLAN GROWTH",
  "id": 103390
}, {
  "name": "TAURUS DISCOVERY (MIDCAP) FUND - REGULAR PLAN GROWTH",
  "id": 103393
}, {
  "name": "TAURUS INFRASTRUCTURE FUND GROWTH",
  "id": 103400
}, {
  "name": "TAURUS FLEXI CAP FUND - REGULAR PLAN GROWTH PLAN",
  "id": 103408
}, {
  "name": "PGIM INDIA ARBITRAGE FUND - REGULAR PLAN - GROWTH",
  "id": 103435
}, {
  "name": "PGIM INDIA BALANCED ADVANTAGE FUND - REGULAR PLAN - GROWTH",
  "id": 103458
}, {
  "name": "PGIM INDIA FLEXI CAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 103464
}, {
  "name": "PGIM INDIA ELSS TAX SAVER FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 103498
}, {
  "name": "BARODA BNP PARIBAS BANKING AND FINANCIAL SERVICES FUND REGULAR GROWTH",
  "id": 103529
}, {
  "name": "NAVI EQUITY HYBRID FUND REGULAR PLAN GROWTH",
  "id": 103610
}, {
  "name": "NAVI FLEXI CAP FUND REGULAR PLAN GROWTH",
  "id": 103640
}, {
  "name": "SHRIRAM ELSS TAX SAVER FUND - REGULAR PLAN - GROWTH",
  "id": 103767
}, {
  "name": "SHRIRAM BALANCED ADVANTAGE FUND REGULAR -  GROWTH",
  "id": 103771
}, {
  "name": "SHRIRAM AGGRESSIVE HYBRID FUND REGULAR PLAN - GROWTH",
  "id": 103779
}, {"name": "JM ARBITRAGE FUND - GROWTH OPTION", "id": 103790}, {
  "name": "LIC MF ARBITRAGE FUND REGULAR PLAN-GROWTH",
  "id": 103901
}, {
  "name": "LIC AGGRESSIVE HYBRID FUND - REGULAR PLAN - GROWTH",
  "id": 103925
}, {
  "name": "LIC MF CHILDRENS FUND-GROWTH-GROWTH",
  "id": 103927
}, {
  "name": "LIC NIFTY 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 103951
}, {
  "name": "LIC MF LARGE AND MIDCAP FUND-REGULAR PLAN-GROWTH-GROWTH",
  "id": 103963
}, {
  "name": "LIC MF ELSS TAX SAVER - REGULAR PLAN - GROWTH",
  "id": 103974
}, {
  "name": "CANARA ROBECO EMERGING EQUITIES REGULAR -GROWTH",
  "id": 104033
}, {
  "name": "GROWW LARGE CAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 104080
}, {
  "name": "GROWW AGGRESSIVE HYBRID FUND-REGULAR PLAN - GROWTH",
  "id": 104098
}, {
  "name": "MAHINDRA MANULIFE ARBITRAGE FUND - REGULAR - GROWTH",
  "id": 104141
}, {
  "name": "UNION ARBITRAGE FUND REGULAR PLAN - GROWTH",
  "id": 104229
}, {
  "name": "UNION EQUITY SAVINGS FUND REGULAR PLAN - GROWTH",
  "id": 104239
}, {
  "name": "UNION AGGRESSIVE HYBRID FUND - REGULAR PLAN - GROWTH",
  "id": 104262
}, {
  "name": "UNION LARGE AND MIDCAP FUND REGULAR PLAN - GROWTH",
  "id": 104268
}, {
  "name": "UNION MIDCAP FUND - REGULAR PLAN - GROWTH",
  "id": 104280
}, {
  "name": "UNION SMALL CAP FUND REGULAR PLAN - GROWTH",
  "id": 104291
}, {
  "name": "UNION VALUE FUND REGULAR PLAN - GROWTH",
  "id": 104301
}, {
  "name": "ITI BALANCED ADVANTAGE FUND- REGULAR PLAN- GROWTH",
  "id": 104346
}, {
  "name": "ITI SMALL CAP FUND REGULAR PLAN GROWTH",
  "id": 104380
}, {
  "name": "TATA INDIA CONSUMER FUND REGULAR PLAN GROWTH",
  "id": 104604
}, {
  "name": "TATA BSE SENSEX INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 104630
}, {
  "name": "KOTAK NIFTY 50 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 104717
}, {
  "name": "MOTILAL OSWAL ASSET ALLOCATION PASSIVE FUND OF FUND - AGGRESSIVE - REGULAR GROWTH",
  "id": 105847
}, {
  "name": "MOTILAL OSWAL ASSET ALLOCATION PASSIVE FUND OF FUND - CONSERVATIVE - REGULAR GROWTH",
  "id": 105849
}, {
  "name": "AXIS CONSERVATIVE HYBRID FUND - REGULAR PLAN - GROWTH",
  "id": 106201
}, {
  "name": "DSP VALUE FUND - REGULAR PLAN - GROWTH",
  "id": 106886
}, {
  "name": "LIC EQUITY SAVINGS FUND - REGULAR PLAN - GROWTH",
  "id": 106974
}, {
  "name": "LIC MID CAP FUND - REGULAR PLAN - GROWTH",
  "id": 107002
}, {
  "name": "LIC MF VALUE FUND - REGULAR PLAN - GROWTH",
  "id": 107030
}, {
  "name": "NIPPON INDIA DYNAMIC BOND FUND - GROWTH PLAN",
  "id": 107832
}, {
  "name": "ICICI PRUDENTIAL SAVINGS FUND - GROWTH",
  "id": 1614
}, {
  "name": "NIPPON INDIA LOW DURATION FUND - RETAIL OPTION - GROWTH PLAN",
  "id": 3272
}, {
  "name": "NIPPON INDIA RETIREMENT FUND-INCOME GENERATION SCHEME-GROWTH PLAN GROWTH OPTION",
  "id": 3553
}, {
  "name": "INVESCO INDIA SHORT TERM PLAN B GROWTH",
  "id": 4091
}, {
  "name": "INVESCO INDIA LOW DURATION FUND - REGULAR GROWTH",
  "id": 4099
}, {
  "name": "INVESCO INDIA BANKING AND PSU FUND - GROWTH",
  "id": 4121
}, {
  "name": "SUNDARAM MEDIUM DURATION FUND REGULAR GROWTH",
  "id": 6398
}, {
  "name": "LIC MF CONSERVATIVE HYBRID FUND-REGULAR PLAN-GROWTH",
  "id": 8436
}, {
  "name": "BANK OF INDIA SHORT TERM INCOME FUNDREGULAR PLAN - GROWTH",
  "id": 9815
}, {
  "name": "GROWW SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 10299
}, {
  "name": "UTI BANKING & PSU FUND-REGULAR PLAN - REGULAR PLAN - GROWTH",
  "id": 11252
}, {
  "name": "FRANKLIN INDIA GOVERNMENT SECURITIES FUND-GROWTH",
  "id": 18238
}, {"name": "KOTAK CREDIT RISK FUND - GROWTH", "id": 18938}, {
  "name": "KOTAK LOW DURATION FUND GROWTH",
  "id": 19257
}, {
  "name": "MOTILAL OSWAL ULTRA SHORT TERM FUND - REGULAR GROWTH",
  "id": 19569
}, {
  "name": "HSBC MEDIUM TO LONG DURATION FUND - GROWTH",
  "id": 19825
}, {
  "name": "EDELWEISS BANKING AND PSU DEBT FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 20445
}, {
  "name": "PARAG PARIKH LIQUID FUND-REGULAR PLAN-GROWTH",
  "id": 24213
}, {
  "name": "MAHINDRA MANULIFE DYNAMIC BOND FUND - REGULAR - GROWTH",
  "id": 25912
}, {"name": "UNION DYNAMIC BOND FUND - GROWTH", "id": 27566}, {
  "name": "MOTILAL OSWAL LIQUID FUND REGULAR GROWTH",
  "id": 30068
}, {
  "name": "LIC SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 30280
}, {
  "name": "ADITYA BIRLA SUN LIFE RETIREMENT FUND - THE 50S PLAN REGULAR GROWTH",
  "id": 31090
}, {
  "name": "ADITYA BIRLA SUN LIFE RETIREMENT FUND - THE 50S PLUS - DEBT PLAN REGULAR GROWTH",
  "id": 31102
}, {"name": "GROWW OVERNIGHT FUND REGULAR PLAN - GROWTH", "id": 32697}, {
  "name": "ITI LIQUID FUND REGULAR PLAN GROWTH",
  "id": 33235
}, {
  "name": "ITI OVERNIGHT FUND REGULAR PLAN GROWTH",
  "id": 33378
}, {
  "name": "AXIS RETIREMENT FUND - CONSERVATIVE PLAN - REGULAR PLAN -GROWTH",
  "id": 35373
}, {
  "name": "DSP FLOATER FUND - REGULAR PLAN - GROWTH",
  "id": 50346
}, {
  "name": "ITI ULTRA SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 50827
}, {
  "name": "AXIS FLOATER FUND REGULAR GROWTH",
  "id": 89279
}, {
  "name": "UNION MONEY MARKET FUND - REGULAR PLAN - GROWTH",
  "id": 89890
}, {"name": "SAMCO OVERNIGHT FUND-REGULAR GROWTH", "id": 97552}, {
  "name": "HSBC CORPORATE BOND FUND-REGULAR-CUMULATIVE",
  "id": 98763
}, {
  "name": "HSBC GILT FUND - GROWTH PLAN",
  "id": 98944
}, {
  "name": "ADITYA BIRLA SUN LIFE ELSS TAX SAVER FUND - REGULAR PLAN -GROWTH",
  "id": 100550
}, {
  "name": "ADITYA BIRLA SUN LIFE INFRASTRUCTURE FUND - GROWTH",
  "id": 100557
}, {
  "name": "ADITYA BIRLA SUN LIFE EQUITY ADVANTAGE FUND - GROWTH-REGULAR PLAN",
  "id": 100567
}, {
  "name": "ADITYA BIRLA SUN LIFE BAL BHAVISHYA YOJNA WEALTH PLAN REGULAR GROWTH",
  "id": 100687
}, {
  "name": "ADITYA BIRLA SUN LIFE RETIREMENT FUND - THE 30S PLAN REGULAR GROWTH",
  "id": 100699
}, {
  "name": "ADITYA BIRLA SUN LIFE RETIREMENT FUND - THE 40S PLAN REGULAR GROWTH",
  "id": 100707
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY SMALLCAP 50 INDEX FUND-NORMAL PLAN-GROWTH",
  "id": 100743
}, {
  "name": "ADITYA BIRLA SUN LIFE EQUITY SAVINGS FUND REGULAR PLAN GROWTH",
  "id": 100789
}, {
  "name": "ADITYA BIRLA SUN LIFE SMALL CAP FUND GROWTH",
  "id": 100795
}, {
  "name": "ADITYA BIRLA SUN LIFE PURE VALUE FUND - GROWTH",
  "id": 100797
}, {
  "name": "HDFC INFRASTRUCTURE FUND - GROWTH OPTION",
  "id": 100897
}, {
  "name": "NIPPON INDIA NIFTY 50 VALUE 20 INDEX FUND REGULAR PLAN GROWTH",
  "id": 101588
}, {"name": "UTI FLEXI CAP FUND-REGULAR PLAN-GROWTH", "id": 102146}, {
  "name": "UTI INDIA CONSUMER FUND - GROWTH",
  "id": 102152
}, {
  "name": "BANK OF INDIA ARBITRAGE FUND- REGULAR PLAN - GROWTH",
  "id": 102254
}, {
  "name": "DSP MIDCAP FUND - REGULAR PLAN - GROWTH",
  "id": 102462
}, {
  "name": "MIRAE ASSET FOCUSED FUND - REGULAR PLAN - GROWTH",
  "id": 102663
}, {
  "name": "SUNDARAM ARBITRAGE FUND  - REGULAR PLAN GROWTH",
  "id": 102913
}, {
  "name": "MOTILAL OSWAL BALANCE ADVANTAGE FUND - REGULAR GROWTH",
  "id": 103002
}, {
  "name": "MOTILAL OSWAL MULTI ASSET FUND REGULAR GROWTH",
  "id": 103021
}, {
  "name": "EDELWEISS GOLD AND SILVER ETF FUND OF FUND -REGULAR PLAN-GROWTH",
  "id": 103115
}, {
  "name": "EDELWEISS NIFTY 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 103145
}, {
  "name": "AXIS CHILDRENS FUND - COMPULSORY LOCK-IN - REGULAR PLAN - GROWTH",
  "id": 103199
}, {
  "name": "AXIS CHILDRENS FUND - NO LOCK-IN - REGULAR PLAN - GROWTH",
  "id": 103202
}, {
  "name": "AXIS AGGRESSIVE HYBRID FUND - REGULAR PLAN - GROWTH",
  "id": 103231
}, {"name": "AXIS FOCUSED FUND - REGULAR GROWTH", "id": 103258}, {
  "name": "AXIS BLUECHIP FUND - REGULAR GROWTH",
  "id": 103261
}, {
  "name": "AXIS MULTI ASSET ALLOCATION FUND - REGULAR GROWTH - GROWTH",
  "id": 103267
}, {
  "name": "AXIS ELSS TAX SAVER FUND - REGULAR GROWTH",
  "id": 103269
}, {
  "name": "AXIS RETIREMENT FUND - AGGRESSIVE PLAN - REGULAR PLAN -GROWTH",
  "id": 103330
}, {
  "name": "AXIS RETIREMENT FUND - DYNAMIC PLAN - REGULAR PLAN -GROWTH",
  "id": 103334
}, {
  "name": "PGIM INDIA LARGE CAP FUND - REGULAR PLAN - GROWTH",
  "id": 103470
}, {
  "name": "PGIM INDIA HYBRID EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 103478
}, {
  "name": "PGIM INDIA SMALL CAP FUND REGULAR PLAN - GROWTH",
  "id": 103494
}, {
  "name": "NAVI LARGE AND MIDCAP FUND - REGULAR PLAN GROWTH",
  "id": 103664
}, {
  "name": "LIC MF BANKING AND FINANCIAL SERVICES FUND- GROWTH PLAN",
  "id": 103919
}, {
  "name": "LIC MF INDEX FUND SENSEX-GROWTH-GROWTH",
  "id": 103945
}, {
  "name": "GROWW ELSS TAX SAVER FUND - REGULAR PLAN- GROWTH OPTION",
  "id": 104108
}, {
  "name": "GROWW VALUE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 104120
}, {"name": "ITI ARBITRAGE FUND REGULAR PLAN GROWTH", "id": 104334}, {
  "name": "ITI LARGE CAP FUND REGULAR PLAN GROWTH",
  "id": 104356
}, {
  "name": "LIC MF MONEY MARKET FUND REGULAR PLAN-GROWTH",
  "id": 105006
}, {
  "name": "LIC HEALTHCARE FUND - REGULAR PLAN - GROWTH",
  "id": 106966
}, {
  "name": "NIPPON INDIA EQUITY SAVINGS FUND--GROWTH",
  "id": 108154
}, {
  "name": "ICICI PRUDENTIAL CONSTANT MATURITY GILT FUND - GROWTH",
  "id": 1735
}, {
  "name": "ICICI PRUDENTIAL INDEX INSTITUTIONAL OPTION I GROWTH",
  "id": 2243
}, {
  "name": "ICICI PRUDENTIAL CORPORATE BOND FUND PREMIUM GROWTH",
  "id": 2264
}, {
  "name": "ICICI PRUDENTIAL SAVINGS FUND - INSTITUTIONAL OPTION - I",
  "id": 2293
}, {
  "name": "ICICI PRUDENTIAL US BLUECHIP EQUITY FUND - GROWTH",
  "id": 2509
}, {
  "name": "ICICI PRUDENTIAL GLOBAL STABLE EQUITY FUND (FOF) GROWTH",
  "id": 2564
}, {
  "name": "ICICI PRUDENTIAL LONG TERM BOND FUND - GROWTH",
  "id": 2595
}, {
  "name": "ICICI PRUDENTIAL MEDIUM TERM BOND FUND C - GROWTH",
  "id": 2766
}, {
  "name": "ICICI PRUDENTIAL MEDIUM TERM BOND FUND INSTITUTIONAL OPTION - I",
  "id": 2768
}, {
  "name": "NIPPON INDIA LIQUID FUND - RETAIL OPTION - GROWTH OPTION",
  "id": 3250
}, {
  "name": "NIPPON INDIA JAPAN EQUITY FUND - GROWTH PLAN - GROWTH OPTION",
  "id": 3455
}, {
  "name": "NIPPON INDIA US EQUITY OPPORTUNITIES FUND--GROWTH PLAN-GROWTH",
  "id": 3588
}, {
  "name": "INVESCO INDIA - INVESCO GLOBAL EQUITY INCOME FUND OF FUND - REGULAR PLAN GROWTH",
  "id": 3986
}, {
  "name": "INVESCO INDIA - INVESCO PAN EUROPEAN EQUITY FUND OF FUND - REGULAR PLAN GROWTH",
  "id": 3992
}, {
  "name": "INVESCO INDIA CORPORATE BOND FUND - PLAN B GROWTH",
  "id": 4014
}, {
  "name": "INVESCO INDIA GILT FUND LONG TERM INSTITUTIONAL GROWTH",
  "id": 4037
}, {
  "name": "INVESCO INDIA LIQUID FUND INSTITUTIONAL GROWTH",
  "id": 4061
}, {
  "name": "BANDHAN 50-50 STRATEGIC SECTOR FUND - PLAN B - GROWTH",
  "id": 5051
}, {
  "name": "BANDHAN GOVERNMENT SECURITIES FUND - CONSTANT MATURITY PLAN -REGULAR PLAN-GROWTH",
  "id": 5251
}, {
  "name": "BANDHAN - SUPER SAVER INCOME FUND - MEDIUM TERM - PLAN D - GROWTH OPTION",
  "id": 5253
}, {
  "name": "BANDHAN - SUPER SAVER INCOME FUND - INVESTMENT PLAN D- GROWTH OPTION",
  "id": 5268
}, {
  "name": "DSP GLOBAL ALLOCATION FUND - REGULAR - GROWTH",
  "id": 5596
}, {
  "name": "DSP 10Y G-SEC FUND - REGULAR PLAN - GROWTH",
  "id": 5606
}, {
  "name": "DSP WORLD GOLD FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 5722
}, {
  "name": "DSP GLOBAL CLEAN ENERGY FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 5736
}, {
  "name": "DSP WORLD MINING FUND - REGULAR PLAN - GROWTH",
  "id": 5740
}, {
  "name": "DSP US FLEXIBLE EQUITY FUND OF FUND - GROWTH",
  "id": 5826
}, {
  "name": "AXIS TREASURY ADVANTAGE FUND - RETAIL GROWTH",
  "id": 7164
}, {
  "name": "PGIM INDIA GLOBAL EQUITY OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 7575
}, {
  "name": "PGIM INDIA EMERGING MARKETS EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 7587
}, {
  "name": "ICICI PRUDENTIAL R.I.G.H.T FUND GROWTH",
  "id": 8798
}, {
  "name": "ADITYA BIRLA SUN LIFE CEF - GLOBAL PRECIOUS METALS PLAN-GROWTH",
  "id": 10543
}, {
  "name": "ADITYA BIRLA SUN LIFE CEF - GLOBAL MULTI COMMODITY PLAN-GROWTH",
  "id": 10555
}, {
  "name": "ADITYA BIRLA SUN LIFE INTERNATIONAL EQUITY FUND - GROWTH",
  "id": 10609
}, {
  "name": "ADITYA BIRLA SUN LIFE LIQUID FUND INSTITUTIONAL GROWTH",
  "id": 11036
}, {"name": "ADITYA BIRLA SUN LIFE LIQUID FUND RETAIL GROWTH", "id": 11079}, {
  "name": "UTI LIQUID FUND",
  "id": 11801
}, {
  "name": "UTI MONEY MARKET FUND-DISCONTINUED REGULAR PLAN-GROWTH",
  "id": 11904
}, {
  "name": "UTI LOW DURATION FUND DISCONTINUED - REGULAR PLAN-GROWTH",
  "id": 12048
}, {
  "name": "ICICI PRUDENTIAL CORPORATE BOND FUND SUPER PREMIUM GROWTH",
  "id": 13322
}, {
  "name": "ICICI PRUDENTIAL FOCUSED EQUITY FUND - INSTITUTIONAL OPTION - I",
  "id": 13325
}, {
  "name": "ICICI PRUDENTIAL LONG TERM BOND FUND - INSTITUTIONAL GROWTH",
  "id": 13373
}, {
  "name": "SUNDARAM MONEY FUND INSTITUTIONAL - APPRECIATION",
  "id": 15704
}, {
  "name": "FRANKLIN INDIA FEEDER - FRANKLIN U.S. OPPORTUNITIES FUND - GROWTH",
  "id": 17947
}, {
  "name": "FRANKLIN INDIA FEEDER - FRANKLIN EUROPEAN GROWTH FUND-GROWTH",
  "id": 18317
}, {
  "name": "KOTAK MULTI ASSET ALLOCATOR FUND OF FUND - DYNAMIC - GROWTH",
  "id": 18842
}, {"name": "HSBC LIQUID  FUND - GROWTH", "id": 19751}, {
  "name": "HSBC LIQUID  FUND - INSTITUTIONAL PLAN - GROWTH",
  "id": 19759
}, {
  "name": "HSBC MEDIUM TO LONG DURATION FUND -INSTITUTIONAL-GROWTH",
  "id": 19827
}, {
  "name": "HSBC SHORT DURATION FUND-INSTITUTIONAL-GROWTH",
  "id": 19836
}, {
  "name": "HSBC SHORT DURATION FUND INSTL PLUS GROWTH",
  "id": 19837
}, {
  "name": "HSBC LOW DURATION FUND- INST.PLUS - GROWTH",
  "id": 19846
}, {
  "name": "EDELWEISS ASEAN EQUITY OFF-SHORE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 20136
}, {
  "name": "EDELWEISS EUROPE DYNAMIC EQUITY OFF-SHORE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 20187
}, {
  "name": "EDELWEISS EMERGING MARKETS OPPORTUNITIES EQUITY OFF-SHORE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 20311
}, {
  "name": "EDELWEISS GREATER CHINA EQUITY OFF-SHORE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 20320
}, {
  "name": "EDELWEISS LIQUID FUND - RETAIL PLAN GROWTH",
  "id": 20380
}, {
  "name": "EDELWEISS ULTRA SHORT TERM FUND - RETAIL PLAN - GROWTH",
  "id": 20489
}, {
  "name": "EDELWEISS US VALUE EQUITY OFF-SHORE FUND- REGULAR PLAN- GROWTH OPTION",
  "id": 20533
}, {
  "name": "TAURUS ULTRA SHORT TERM BOND FUND - RETAIL GROWTH",
  "id": 20780
}, {
  "name": "TAURUS ULTRA SHORT TERM BOND FUND - SUPER INSTITUTIONAL GROWTH",
  "id": 20783
}, {
  "name": "ADITYA BIRLA SUN LIFE GLOBAL EMERGING OPPORTUNITIES FUND GROWTH",
  "id": 25774
}, {
  "name": "FRANKLIN INDIA LIQUID FUND - REGULAR PLAN - GROWTH",
  "id": 27924
}, {
  "name": "JM LIQUID FUND - SUPER INSTITUTIONAL PLAN - GROWTH OPTION",
  "id": 29829
}, {
  "name": "ICICI PRUDENTIAL GLOBAL ADVANTAGE FUND (FOF) GROWTH",
  "id": 33263
}, {
  "name": "SBI LIQUID FUND INSTITUTIONAL GROWTH GROWTH",
  "id": 33775
}, {
  "name": "ADITYA BIRLA SUN LIFE GLOBAL EXCELLENCE EQUITY FUND OF FUND RETAIL PLAN GROWTH",
  "id": 35043
}, {
  "name": "SBI CREDIT RISK FUND GROWTH GROWTH",
  "id": 36758
}, {
  "name": "EDELWEISS US TECHNOLOGY EQUITY FUND OF FUND-REGULAR PLAN-GROWTH",
  "id": 37067
}, {
  "name": "ICICI PRUDENTIAL US BLUECHIP EQUITY FUND - GROWTH-INSURED",
  "id": 37873
}, {
  "name": "ICICI PRUDENTIAL GLOBAL STABLE EQUITY FUND (FOF) GROWTH-INSURED",
  "id": 37878
}, {
  "name": "MOTILAL OSWAL S&P 500 INDEX FUND REGULAR GROWTH",
  "id": 37927
}, {
  "name": "AXIS GLOBAL EQUITY ALPHA FUND OF FUND REGULAR GROWTH",
  "id": 38870
}, {
  "name": "INVESCO INDIA - INVESCO GLOBAL CONSUMER TRENDS FUND OF FUND - REGULAR PLAN GROWTH",
  "id": 49195
}, {
  "name": "BANDHAN EQUITY OPPORTUNITY - SERIES 4-REGULAR PLAN GROWTH",
  "id": 49655
}, {
  "name": "AXIS GREATER CHINA EQUITY FUND OF FUND REGULAR GROWTH",
  "id": 50087
}, {
  "name": "SBI INTERNATIONAL ACCESS - US EQUITY FOF REGULAR PLAN GROWTH",
  "id": 50206
}, {
  "name": "BANDHAN CRISIL IBX GILT JUNE 2027 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 50280
}, {
  "name": "BANDHAN CRISIL IBX GILT APRIL 2028 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 50288
}, {
  "name": "EDELWEISS NIFTY PSU BOND PLUS SDL APR 2026 50:50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 50425
}, {
  "name": "MIRAE ASSET NYSE FANG ETF FUND OF FUND REGULAR PLAN GROWTH",
  "id": 50815
}, {
  "name": "AXIS GLOBAL INNOVATION FUND OF FUND REGULAR GROWTH",
  "id": 88810
}, {
  "name": "BANDHAN US EQUITY FUND OF FUND REGULAR PLAN-GROWTH",
  "id": 89701
}, {
  "name": "MIRAE ASSET S&P 500 TOP 50 ETF FUND OF FUND REGULAR PLAN GROWTH",
  "id": 89948
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY SDL PLUS PSU BOND SEP 2026 60:40 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 90080
}, {
  "name": "ICICI PRUDENTIAL NIFTY PSU BOND PLUS SDL SEP 2027 40:60 INDEX FUND GROWTH",
  "id": 90135
}, {
  "name": "HDFC DEVELOPED WORLD INDEXES FUND OF FUNDS - REGULAR PLAN - GROWTH OPTION",
  "id": 90180
}, {
  "name": "ICICI PRUDENTIAL NASDAQ 100 INDEX FUND - GROWTH",
  "id": 90246
}, {
  "name": "MAHINDRA MANULIFE ASIA PACIFIC REITS FOF - REGULAR - GROWTH",
  "id": 90260
}, {
  "name": "EDELWEISS NIFTY PSU BOND PLUS SDL APR 2027 50:50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 90279
}, {
  "name": "AXIS AAA BOND PLUS SDL ETF - 2026 MATURITY FUND OF FUND REGULAR GROWTH",
  "id": 90285
}, {
  "name": "ADITYA BIRLA SUN LIFE NASDAQ 100 FOF-REGULAR -GROWTH",
  "id": 90386
}, {
  "name": "NIPPON INDIA? FIXED HORIZON FUND - XXXVIII - SERIES 11 - GROWTH PLAN",
  "id": 90437
}, {
  "name": "PGIM INDIA GLOBAL SELECT REAL ESTATE SECURITIES FUND OF FUND REGULAR PLAN - GROWTH",
  "id": 90692
}, {
  "name": "MIRAE ASSET HANG SENG TECH ETF FUND OF FUND REGULAR PLAN GROWTH",
  "id": 90762
}, {
  "name": "NIPPON INDIA TAIWAN EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 90845
}, {
  "name": "SBI CPSE BOND PLUS SDL SEP 2026 50:50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 93479
}, {
  "name": "AXIS CPSE PLUS SDL 2025 70:30 DEBT INDEX FUND REGULAR GROWTH",
  "id": 93544
}, {
  "name": "ADITYA BIRLA SUN LIFE SILVER ETF FOF-REGULAR PLAN-GROWTH",
  "id": 93548
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY SDL APR 2027 INDEX FUND REGULAR GROWTH",
  "id": 93570
}, {
  "name": "ICICI PRUDENTIAL STRATEGIC METAL AND ENERGY EQUITY FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 93579
}, {
  "name": "DSP GLOBAL INNOVATION FUND OF FUND - REGULAR - GROWTH",
  "id": 93626
}, {
  "name": "KOTAK NIFTY SDL APR2027 TOP 12 EQUAL WEIGHT INDEX FUND-REGULAR PLAN - GROWTH",
  "id": 93718
}, {
  "name": "KOTAK NIFTY SDL APR2032 TOP 12 EQUAL WEIGHT INDEX FUND-REGULAR PLAN - GROWTH",
  "id": 93726
}, {
  "name": "AXIS CRISIL SDL 2027 DEBT INDEX FUND REGULAR GROWTH",
  "id": 93753
}, {
  "name": "EDELWEISS CRISIL PSU PLUS SDL50:50 OCT 2025 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 94236
}, {
  "name": "DSP NIFTY SDL PLUS G-SEC JUN 2028 30:70 INDEX FUND - REGULAR - GROWTH",
  "id": 94901
}, {
  "name": "BARODA BNP PARIBAS FUNDS AQUA FUND OF FUND REGULAR GROWTH",
  "id": 94921
}, {
  "name": "BARODA BNP PARIBAS CORPORATE BOND FUND - REGULAR PLAN - GROWTH",
  "id": 94980
}, {
  "name": "BARODA BNP PARIBAS DYNAMIC BOND FUND - DEFUNCT GROWTH",
  "id": 95003
}, {
  "name": "BARODA BNP PARIBAS LOW DURATION FUND REGULAR GROWTH",
  "id": 95110
}, {
  "name": "ICICI PRUDENTIAL NIFTY SDL SEP 2027 INDEX FUND - GROWTH",
  "id": 95282
}, {
  "name": "TATA NIFTY SDL PLUS AAA PSU BOND DEC 2027 60: 40 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 95431
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX 60:40 SDL   AAA PSU - APR 2025 INDEX FUND-REGULAR GROWTH",
  "id": 95434
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX 60:40 SDL   AAA PSU - APR 2027 INDEX FUND-REGULAR GROWTH",
  "id": 95438
}, {
  "name": "MIRAE ASSET NIFTY SDL JUN 2027 INDEX FUND REGULAR PLAN GROWTH",
  "id": 95446
}, {
  "name": "INVESCO INDIA - INVESCO EQQQ NASDAQ-100 ETF FUND OF FUND REGULAR PLAN GROWTH",
  "id": 95490
}, {"name": "FRANKLIN PENSION PLAN - GROWTH", "id": 95541}, {
  "name": "SBI MAGNUM CONSTANT MATURITY FUND REGULAR GROWTH",
  "id": 95731
}, {
  "name": "ICICI PRUDENTIAL PASSIVE MULTI-ASSET FOF GROWTH",
  "id": 95763
}, {
  "name": "UTI GILT FUND WITH 10 YEAR CONSTANT DURATION- REGULAR PLAN - GROWTH",
  "id": 96391
}, {
  "name": "ADITYA BIRLA SUN LIFE LONG DURATION FUND - REGULAR GROWTH",
  "id": 96509
}, {
  "name": "BHARAT BOND FOF - APRIL 2030 - REGULAR PLAN - GROWTH",
  "id": 96765
}, {
  "name": "BHARAT BOND FOF - APRIL 2031 - REGULAR PLAN - GROWTH",
  "id": 96771
}, {
  "name": "BHARAT BOND ETF FOF - APRIL 2032 - REGULAR PLAN - GROWTH",
  "id": 96777
}, {
  "name": "BHARAT BOND FOF - APRIL 2025 - REGULAR PLAN - GROWTH",
  "id": 96783
}, {
  "name": "MIRAE ASSET GLOBAL X ARTIFICIAL INTELLIGENCE & TECHNOLOGY ETF FUND OF FUND REGULAR PLAN GROWTH",
  "id": 96975
}, {
  "name": "MIRAE ASSET GLOBAL ELECTRIC & AUTONOMOUS VEHICLES ETFS FUND OF FUND REGULAR PLAN GROWTH",
  "id": 96979
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY SDL SEP 2025 INDEX FUND - REGULAR PLAN-GROWTH",
  "id": 97202
}, {
  "name": "TATA CRISIL-IBX GILT INDEX - APRIL 2026 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 97256
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX GILT -APRIL 2026 INDEX FUND-REGULAR GROWTH",
  "id": 97382
}, {
  "name": "SBI CRISIL IBX GILT INDEX - JUNE 2036 FUND - REGULAR PLAN - GROWTH",
  "id": 97436
}, {
  "name": "SBI CRISIL IBX GILT INDEX - APRIL 2029 FUND - REGULAR PLAN - GROWTH",
  "id": 97444
}, {
  "name": "SBI CRISIL IBX SDL INDEX - SEPTEMBER 2027 FUND - REGULAR PLAN - GROWTH",
  "id": 97452
}, {
  "name": "EDELWEISS CRISIL IBX 50:50 GILT PLUS SDL APRIL 2037 INDEX FUND- REGULAR PLAN GROWTH - GROWTH",
  "id": 97528
}, {
  "name": "ICICI PRUDENTIAL NIFTY G-SEC DEC 2030 INDEX FUND - GROWTH",
  "id": 97534
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX 50:50 GILT PLUS SDL APR 2028 INDEX FUND-REGULAR GROWTH",
  "id": 97572
}, {
  "name": "ICICI PRUDENTIAL NIFTY SDL DEC 2028 INDEX FUND - GROWTH",
  "id": 97578
}, {
  "name": "KOTAK NIFTY SDL PLUS AAA PSU BOND JUL 2028 60:40 INDEX FUND -REGULAR PLAN - GROWTH",
  "id": 97594
}, {
  "name": "ADITYA BIRLA SUN LIFE MULTI- INDEX FUND OF FUNDS-REGULAR GROWTH",
  "id": 97622
}, {
  "name": "EDELWEISS CRISIL IBX 50:50 GILT PLUS SDL JUN 2027 INDEX FUND- REGULAR PLAN GROWTH",
  "id": 97648
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX GILT APR 2029 INDEX FUND-REGULAR GROWTH",
  "id": 97652
}, {
  "name": "BANDHAN CRISIL IBX GILT APRIL 2026 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 97658
}, {
  "name": "MIRAE ASSET CRISIL IBX GILT INDEX - APRIL 2033 INDEX FUND REGULAR PLAN GROWTH",
  "id": 97684
}, {
  "name": "MIRAE ASSET NIFTY AAA PSU BOND PLUS SDL APR 2026 50:50 INDEX FUND REGULAR PLAN GROWTH",
  "id": 97696
}, {
  "name": "AXIS NASDAQ 100 FUND OF FUND REGULAR GROWTH",
  "id": 97846
}, {
  "name": "EDELWEISS CRISIL IBX 5050 GILT PLUS SDL SEP 2028 INDEX FUND- REGULAR PLAN GROWTH",
  "id": 97934
}, {
  "name": "HDFC NIFTY G SEC DEC 2026 INDEX FUND REGULAR GROWTH",
  "id": 97940
}, {
  "name": "HDFC NIFTY G SEC JULY 2031 INDEX FUND REGULAR GROWTH",
  "id": 97944
}, {
  "name": "BANDHAN CRISIL IBX 90:10 SDL PLUS GILT NOVEMBER 2026 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 97955
}, {
  "name": "BANDHAN CRISIL IBX 90:10 SDL PLUS GILT SEPTEMBER 2027 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 98701
}, {
  "name": "AXIS NIFTY SDL SEPTEMBER 2026 DEBT INDEX FUND REGULAR GROWTH",
  "id": 98727
}, {
  "name": "BANDHAN CRISIL IBX 90:10 SDL PLUS GILT APRIL 2032 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 99375
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX 60:40 SDL  AAA PSU APR 2026 INDEX FUND-REGULAR GROWTH",
  "id": 99495
}, {
  "name": "HDFC NIFTY G- SEC JUN 2027 INDEX FUND REGULAR GROWTH",
  "id": 99582
}, {
  "name": "HDFC NIFTY G-SEC SEP 2032 V1 INDEX FUND REGULAR GROWTH",
  "id": 99586
}, {
  "name": "BHARAT BOND ETF FOF-APRIL 2033 - REGULAR PLAN - GROWTH",
  "id": 99646
}, {
  "name": "NIPPON INDIA NIFTY G-SEC- JUN 2036 MATURITY INDEX FUND GROWTH PLAN",
  "id": 99688
}, {
  "name": "UTI CRISIL SDL MATURITY APRIL 2033 INDEX FUND-REGULAR PLAN - GROWTH",
  "id": 99707
}, {
  "name": "ICICI PRUDENTIAL NIFTY SDL SEP 2026 INDEX FUND - GROWTH",
  "id": 99754
}, {
  "name": "SBI LONG DURATION FUND - REGULAR GROWTH",
  "id": 99790
}, {
  "name": "KOTAK NIFTY SDL JUL 2026 INDEX FUND -REGULAR PLAN-GROWTH",
  "id": 99800
}, {
  "name": "AXIS LONG DURATION FUND REGULAR GROWTH",
  "id": 99850
}, {
  "name": "UTI CRISIL SDL MATURITY JUNE 2027 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 99940
}, {
  "name": "MOTILAL OSWAL NASDAQ 100 FUND OF FUND - REGULAR GROWTH",
  "id": 99961
}, {
  "name": "TATA NIFTY G-SEC DEC 2029 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 100048
}, {
  "name": "TATA NIFTY G-SEC DEC 2026 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 100069
}, {
  "name": "HDFC LONG DURATION DEBT FUND REGULAR GROWTH",
  "id": 100101
}, {
  "name": "BARODA BNP PARIBAS NIFTY SDL DECEMBER 2026 INDEX FUND REGULAR PLAN GROWTH",
  "id": 100119
}, {
  "name": "BANDHAN MULTI CAP FUND REGULAR PLAN - GROWTH",
  "id": 100199
}, {
  "name": "BANDHAN NIFTY 100 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 100203
}, {
  "name": "BANDHAN MIDCAP FUND REGULAR PLAN-GROWTH",
  "id": 100206
}, {
  "name": "BANDHAN NIFTY200 MOMENTUM 30 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 100209
}, {
  "name": "BANDHAN NIFTY100 LOW VOLATILITY 30 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 100212
}, {
  "name": "BANDHAN TRANSPORTATION AND LOGISTICS FUND REGULAR PLAN-GROWTH",
  "id": 100215
}, {
  "name": "KOTAK GLOBAL EMERGING MARKET FUND GROWTH",
  "id": 100324
}, {
  "name": "KOTAK INTERNATIONAL REIT FOF - REGULAR PLAN - GROWTH",
  "id": 100332
}, {
  "name": "KOTAK NASDAQ 100 FUND OF FUND - REGULAR GROWTH",
  "id": 100340
}, {
  "name": "KOTAK GLOBAL INNOVATION FUND OF FUND-REGULAR PLAN-GROWTH",
  "id": 100342
}, {
  "name": "DSP CRISIL SDL PLUS G-SEC APR 2033 50:50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 100375
}, {
  "name": "HSBC BUSINESS CYCLES FUND GROWTH",
  "id": 100504
}, {
  "name": "AXIS CRISIL IBX 50:50 GILT PLUS SDL JUNE 2028 INDEX FUND REGULAR GROWTH",
  "id": 100547
}, {
  "name": "ADITYA BIRLA SUN LIFE DIGITAL INDIA FUND-GROWTH",
  "id": 100564
}, {
  "name": "ADITYA BIRLA SUN LIFE MNC FUND-GROWTH",
  "id": 100573
}, {
  "name": "ADITYA BIRLA SUN LIFE DIVIDEND YIELD FUND - GROWTH",
  "id": 100579
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY NEXT 50 INDEX FUND - REGULAR GROWTH",
  "id": 100594
}, {
  "name": "ADITYA BIRLA SUN LIFE MANUFACTURING EQUITY FUND-REGULAR PLAN-GROWTH",
  "id": 100645
}, {
  "name": "ADITYA BIRLA SUN LIFE PSU EQUITY FUND NORMAL-GROWTH",
  "id": 100722
}, {
  "name": "ADITYA BIRLA SUN LIFE SPECIAL OPPORTUNITIES FUND-NORMAL PLAN-GROWTH",
  "id": 100727
}, {
  "name": "ADITYA BIRLA SUN LIFE ESG INTEGRATION STRATEGY FUND-NORMAL PLAN-GROWTH",
  "id": 100735
}, {
  "name": "ADITYA BIRLA SUN LIFE MULTI-CAP FUND-NORMAL PLAN-GROWTH",
  "id": 100758
}, {
  "name": "ADITYA BIRLA SUN LIFE BUSINESS CYCLE FUND REGULAR GROWTH",
  "id": 100770
}, {
  "name": "HDFC ARBITRAGE FUND - RETAIL PLAN - GROWTH OPTION",
  "id": 100825
}, {
  "name": "HDFC NIFTY 100 INDEX FUND REGULAR GROWTH",
  "id": 100839
}, {
  "name": "HDFC NIFTY 100 EQUAL WEIGHT INDEX FUND REGULAR GROWTH",
  "id": 100841
}, {
  "name": "HDFC CHILDRENS GIFT FUND (LOCK IN) - REGULAR PLAN GROWTH",
  "id": 100876
}, {
  "name": "HDFC DIVIDEND YIELD FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 100887
}, {
  "name": "HDFC BUSINESS CYCLE FUND REGULAR GROWTH",
  "id": 100920
}, {
  "name": "HDFC MULTI CAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 100927
}, {
  "name": "HDFC HOUSING OPPORTUNITIES FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 100933
}, {
  "name": "HDFC NIFTY NEXT50 INDEX FUND REGULAR GROWTH",
  "id": 100958
}, {
  "name": "ICICI PRUDENTIAL DIVIDEND YIELD EQUITY FUND - GROWTH",
  "id": 100982
}, {
  "name": "ICICI PRUDENTIAL FMCG FUND - GROWTH",
  "id": 101093
}, {
  "name": "ICICI PRUDENTIAL LONG TERM WEALTH ENHANCEMENT FUND GROWTH",
  "id": 101120
}, {
  "name": "ICICI PRUDENTIAL MANUFACTURING FUND REGULAR PLAN GROWTH",
  "id": 101129
}, {
  "name": "ICICI PRUDENTIAL INDIA OPPORTUNITIES FUND GROWTH",
  "id": 101133
}, {"name": "ICICI PRUDENTIAL MNC FUND GROWTH", "id": 101145}, {
  "name": "ICICI PRUDENTIAL COMMODITIES FUND GROWTH",
  "id": 101146
}, {
  "name": "ICICI PRUDENTIAL ESG EXCLUSIONARY STRATEGY FUND GROWTH",
  "id": 101150
}, {
  "name": "ICICI PRUDENTIAL QUANT FUND - REGULAR PLAN -  GROWTH",
  "id": 101154
}, {
  "name": "ICICI PRUDENTIAL BUSINESS CYCLE FUND GROWTH",
  "id": 101158
}, {
  "name": "ICICI PRUDENTIAL NIFTY SMALLCAP 250 INDEX FUND - GROWTH",
  "id": 101174
}, {
  "name": "ICICI PRUDENTIAL BSE 500 ETF FOF GROWTH",
  "id": 101178
}, {
  "name": "ICICI PRUDENTIAL NIFTY MIDCAP 150 INDEX FUND - GROWTH",
  "id": 101182
}, {
  "name": "ICICI PRUDENTIAL SILVER ETF FUND OF FUND GROWTH",
  "id": 101186
}, {
  "name": "ICICI PRUDENTIAL NIFTY BANK INDEX FUND - GROWTH",
  "id": 101190
}, {
  "name": "ICICI PRUDENTIAL HOUSING OPPORTUNITIES FUND - GROWTH",
  "id": 101194
}, {
  "name": "ICICI PRUDENTIAL NIFTY 200 MOMENTUM 30 INDEX FUND - GROWTH",
  "id": 101198
}, {
  "name": "ICICI PRUDENTIAL NIFTY IT INDEX FUND - GROWTH",
  "id": 101202
}, {
  "name": "ICICI PRUDENTIAL PSU EQUITY FUND - GROWTH",
  "id": 101206
}, {
  "name": "ICICI PRUDENTIAL NIFTY50 EQUAL WEIGHT INDEX FUND - GROWTH",
  "id": 101210
}, {
  "name": "ICICI PRUDENTIAL NIFTY AUTO INDEX FUND - GROWTH",
  "id": 101214
}, {
  "name": "ICICI PRUDENTIAL TRANSPORTATION AND LOGISTICS FUND - GROWTH",
  "id": 101218
}, {
  "name": "ICICI PRUDENTIAL NIFTY PHARMA INDEX FUND-GROWTH",
  "id": 101222
}, {
  "name": "ICICI PRUDENTIAL MULTICAP FUND - GROWTH",
  "id": 101359
}, {
  "name": "ICICI PRUDENTIAL THEMATIC ADVANTAGE FUND (FOF) - GROWTH",
  "id": 101394
}, {
  "name": "ICICI PRUDENTIAL EXPORTS AND SERVICES - GROWTH",
  "id": 101414
}, {
  "name": "ICICI PRUDENTIAL TECHNOLOGY FUND - GROWTH",
  "id": 101416
}, {
  "name": "NIPPON INDIA MULTI CAP FUND - GROWTH PLAN",
  "id": 101447
}, {
  "name": "NIPPON INDIA QUANT FUND- GROWTH PLAN - GROWTH OPTION",
  "id": 101517
}, {
  "name": "NIPPON INDIA NIFTY ALPHA LOW VOLATILITY 30 INDEX FUND GROWTH",
  "id": 101570
}, {"name": "NIPPON INDIA SILVER ETF FOF - GROWTH", "id": 101623}, {
  "name": "SBI MAGNUM EQUITY ESG FUND REGULAR GROWTH",
  "id": 101678
}, {"name": "SBI MAGNUM GLOBAL FUND - GROWTH", "id": 101686}, {
  "name": "SBI MAGNUM COMMA FUND - GROWTH",
  "id": 101708
}, {
  "name": "SBI TAX ADVANTAGE FUND - SERIES III - GROWTH",
  "id": 101729
}, {
  "name": "SBI TECHNOLOGY OPPORTUNITIES FUND REGULAR GROWTH",
  "id": 101736
}, {
  "name": "SBI TAX ADVANTAGE FUND-SERIES -II-GROWTH GROWTH",
  "id": 101756
}, {
  "name": "SBI LONG TERM ADVANTAGE FUND - SERIES I -REGULAR PLAN - GROWTH",
  "id": 101762
}, {
  "name": "SBI LONG TERM ADVANTAGE FUND SERIES II REGULAR PLAN GROWTH",
  "id": 101764
}, {
  "name": "SBI LONG TERM ADVANTAGE FUND SERIES III REGULAR PLAN GROWTH",
  "id": 101777
}, {
  "name": "SBI LONG TERM ADVANTAGE FUND SERIES IV REGULAR PLAN GROWTH",
  "id": 101779
}, {
  "name": "SBI LONG TERM ADVANTAGE FUND - SERIES V - REGULAR - GROWTH",
  "id": 101781
}, {
  "name": "SBI LONG TERM ADVANTAGE FUND SERIES VI REGULAR PLAN GROWTH",
  "id": 101783
}, {
  "name": "SBI EQUITY MINIMUM VARIANCE FUND - REGULAR PLAN GROWTH",
  "id": 101787
}, {
  "name": "SBI MULTICAP FUND - REGULAR PLAN - GROWTH",
  "id": 101804
}, {
  "name": "SBI NIFTY MIDCAP 150 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 101808
}, {
  "name": "SBI NIFTY SMALLCAP 250 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 101812
}, {
  "name": "SBI PSU FUND - GROWTH",
  "id": 101961
}, {
  "name": "INVESCO INDIA ESG INTEGRATION STRATEGY FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 101989
}, {
  "name": "INVESCO INDIA FLEXI CAP FUND-REGULAR PLAN - GROWTH",
  "id": 102001
}, {"name": "INVESCO INDIA MULTICAP FUND - GROWTH", "id": 102029}, {
  "name": "INVESCO INDIA PSU EQUITY FUND - GROWTH",
  "id": 102032
}, {
  "name": "QUANTUM ESG BEST IN CLASS STRATEGY FUND - REGULAR PLAN- GROWTH",
  "id": 102083
}, {
  "name": "QUANTUM NIFTY 50 ETF FUND OF FUND - REGULAR PLAN GROWTH",
  "id": 102089
}, {"name": "UTI DIVIDEND YIELD FUND - GROWTH OPTION", "id": 102143}, {
  "name": "UTI MNC FUND - GROWTH PLAN",
  "id": 102161
}, {
  "name": "UTI TRANSPORTATION AND LOGISTIC SECTOR - GROWTH PLAN",
  "id": 102180
}, {
  "name": "UTI BSE LOW VOLATILITY INDEX FUND - REGULAR GROWTH PLAN",
  "id": 102182
}, {
  "name": "UTI NIFTY MIDCAP 150 QUALITY 50 INDEX FUND - REGULAR PLAN GROWTH",
  "id": 102196
}, {"name": "UTI BSE SENSEX INDEX FUND - REGULAR GROWTH PLAN", "id": 102220}, {
  "name": "DSP QUANT FUND - REG - GROWTH",
  "id": 102359
}, {
  "name": "DSP NIFTY MIDCAP 150 QUALITY 50 INDEX FUND - REGULAR - GROWTH",
  "id": 102367
}, {
  "name": "DSP NATURAL RESOURCES AND NEW ENERGY FUND - REGULAR PLAN - GROWTH",
  "id": 102472
}, {
  "name": "KOTAK MULTICAP FUND - REGULAR PLAN - GROWTH",
  "id": 102527
}, {
  "name": "KOTAK MANUFACTURE IN INDIA FUND REGULAR PLAN GROWTH",
  "id": 102563
}, {
  "name": "KOTAK BUSINESS CYCLE FUND-REGULAR PLAN-GROWTH",
  "id": 102571
}, {
  "name": "KOTAK PIONEER FUND - REGULAR PLAN - GROWTH",
  "id": 102600
}, {
  "name": "KOTAK ESG EXCLUSIONARY STRATEGY FUND-REGULAR PLAN - GROWTH",
  "id": 102608
}, {
  "name": "MIRAE ASSET BALANCED ADVANTAGE FUND REGULAR PLAN GROWTH",
  "id": 102633
}, {
  "name": "MIRAE ASSET NIFTY INDIA MANUFACTURING ETF FUND OF FUND REGULAR PLAN GROWTH",
  "id": 102696
}, {
  "name": "MIRAE ASSET ESG SECTOR LEADERS FUND OF FUND REGULAR PLAN - GROWTH",
  "id": 102705
}, {
  "name": "FRANKLIN INDIA TECHNOLOGY FUND - GROWTH",
  "id": 102755
}, {
  "name": "FRANKLIN INDIA OPPORTUNITIES FUND-GROWTH",
  "id": 102764
}, {"name": "TEMPLETON INDIA EQUITY INCOME FUND-GROWTH", "id": 102775}, {
  "name": "FRANKLIN ASIAN EQUITY FUND-GROWTH",
  "id": 102781
}, {
  "name": "FRANKLIN INDIA BALANCED ADVANTAGE FUND -REGULAR PLAN-GROWTH",
  "id": 102837
}, {
  "name": "SUNDARAM GLOBAL BRAND FUND GROWTH",
  "id": 102866
}, {
  "name": "SUNDARAM LONG TERM MICRO CAP TAX ADV FUND SR3-10 YRS - REGULAR PLAN GROWTH",
  "id": 102899
}, {
  "name": "SUNDARAM LONG TERM MICRO CAP TAX ADV FUND SR4-10 YRS REGULAR GROWTH",
  "id": 102903
}, {
  "name": "SUNDARAM LONG TERM MICRO CAP TAX ADV FUND SR 5-10 YRS REGULAR GROWTH",
  "id": 102907
}, {
  "name": "SUNDARAM LONG TERM MICRO CAP TAX ADV FUND SR6-10 YRS REGULAR GROWTH",
  "id": 102911
}, {
  "name": "SUNDARAM DIVIDEND YIELD FUND - REGULAR  GROWTH",
  "id": 102921
}, {
  "name": "SUNDARAM FLEXI CAP FUND REGULAR GROWTH",
  "id": 102929
}, {
  "name": "SUNDARAM LONG TERM TAX ADVANTAGE FUND SERIES I REGULAR PLAN GROWTH",
  "id": 102937
}, {
  "name": "SUNDARAM LONG TERM TAX ADVANTAGE FUND SERIES II REGULAR PLAN GROWTH",
  "id": 102941
}, {
  "name": "SUNDARAM MULTI CAP FUND  - REGULAR PLAN GROWTH",
  "id": 102943
}, {
  "name": "SUNDARAM SERVICES FUND-REGULAR PLAN-GROWTH",
  "id": 102969
}, {
  "name": "MOTILAL OSWAL NIFTY 200 MOMENTUM 30 INDEX FUND - REGULAR GROWTH",
  "id": 103027
}, {
  "name": "MOTILAL OSWAL BSE LOW VOLATILITY INDEX FUND REGULAR GROWTH",
  "id": 103035
}, {
  "name": "MOTILAL OSWAL BSE FINANCIALS EX BANK 30 INDEX FUND REGULAR GROWTH",
  "id": 103037
}, {
  "name": "MOTILAL OSWAL BSE ENHANCED VALUE INDEX FUND REGULAR PLAN GROWTH",
  "id": 103039
}, {
  "name": "MOTILAL OSWAL BSE QUALITY INDEX FUND REGULAR PLAN GROWTH",
  "id": 103041
}, {
  "name": "EDELWEISS FOCUSED FUND - REGULAR PLAN - GROWTH",
  "id": 103109
}, {
  "name": "EDELWEISS NIFTY LARGE MID CAP 250 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 103121
}, {
  "name": "EDELWEISS NIFTY MIDCAP150 MOMENTUM 50 INDEX FUND- REGULAR PLAN GROWTH - GROWTH",
  "id": 103127
}, {
  "name": "EDELWEISS RECENTLY LISTED IPO FUND - REGULAR PLAN - GROWTH",
  "id": 103133
}, {
  "name": "EDELWEISS NIFTY NEXT 50 INDEX FUND- REGULAR PLAN GROWTH - GROWTH",
  "id": 103151
}, {
  "name": "EDELWEISS NIFTY 100 QUALITY 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 103157
}, {
  "name": "EDELWEISS NIFTY SMALLCAP 250 INDEX FUND- REGULAR PLAN GROWTH - GROWTH",
  "id": 103169
}, {
  "name": "AXIS ESG INTEGRATION STRATEGY FUND REGULAR GROWTH",
  "id": 103220
}, {"name": "AXIS EQUITY ETFS FOF REGULAR GROWTH", "id": 103255}, {
  "name": "AXIS MULTICAP FUND REGULAR GROWTH",
  "id": 103284
}, {
  "name": "AXIS NIFTY 50 INDEX FUND REGULAR GROWTH",
  "id": 103290
}, {
  "name": "AXIS NIFTY NEXT 50 INDEX FUND REGULAR GROWTH",
  "id": 103296
}, {
  "name": "AXIS NIFTY MIDCAP 50 INDEX FUND REGULAR GROWTH",
  "id": 103308
}, {
  "name": "AXIS NIFTY SMALLCAP 50 INDEX FUND REGULAR GROWTH",
  "id": 103314
}, {"name": "AXIS QUANT FUND REGULAR GROWTH", "id": 103326}, {
  "name": "AXIS INNOVATION FUND REGULAR GROWTH",
  "id": 103346
}, {
  "name": "AXIS SILVER FUND OF FUND REGULAR GROWTH",
  "id": 103352
}, {
  "name": "360 ONE QUANT FUND - REGULAR PLAN - GROWTH",
  "id": 103383
}, {
  "name": "360 ONE ELSS TAX SAVER NIFTY 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 103387
}, {"name": "TAURUS ETHICAL FUND GROWTH", "id": 103397}, {
  "name": "TAURUS NIFTY INDEX FUND GROWTH",
  "id": 103405
}, {
  "name": "BARODA BNP PARIBAS BUSINESS CYCLE FUND REGULAR GROWTH",
  "id": 103525
}, {
  "name": "BARODA BNP PARIBAS FLEXI CAP FUND REGULAR GROWTH",
  "id": 103553
}, {
  "name": "BARODA BNP PARIBAS MULTI CAP FUND REGULAR GROWTH",
  "id": 103556
}, {
  "name": "BARODA BNP PARIBAS MULTI ASSET FUND REGULAR GROWTH",
  "id": 103580
}, {
  "name": "NAVI NIFTY BANK INDEX FUND - REGULAR PLAN GROWTH",
  "id": 103650
}, {
  "name": "NAVI NIFTY MIDCAP 150 INDEX FUND - REGULAR PLAN GROWTH",
  "id": 103654
}, {
  "name": "NAVI NIFTY NEXT 50 INDEX FUND - REGULAR PLAN GROWTH",
  "id": 103656
}, {
  "name": "NAVI ELSS TAX SAVER FUND - REGULAR PLAN GROWTH",
  "id": 103658
}, {
  "name": "NAVI NIFTY INDIA MANUFACTURING INDEX FUND - REGULAR PLAN GROWTH",
  "id": 103668
}, {
  "name": "JM MIDCAP FUND-(REGULAR)-GROWTH OPTION",
  "id": 103889
}, {
  "name": "LIC MF BALANCED ADVANTAGE FUND REGULAR PLAN-GROWTH",
  "id": 103913
}, {
  "name": "LIC MF MULTI CAP FUND REGULAR PLAN-GROWTH",
  "id": 103969
}, {
  "name": "CANARA ROBECO MID CAP FUND REGULAR GROWTH",
  "id": 104007
}, {
  "name": "MAHINDRA MANULIFE BALANCED ADVANTAGE FUND - REGULAR PLAN - GROWTH",
  "id": 104149
}, {
  "name": "MAHINDRA MANULIFE MULTI CAP FUND - REGULAR - GROWTH",
  "id": 104157
}, {
  "name": "MAHINDRA MANULIFE SMALL CAP FUND - REGULAR - GROWTH",
  "id": 104225
}, {"name": "UNION MULTICAP FUND-REGULAR PLAN-GROWTH", "id": 104274}, {
  "name": "UNION RETIREMENT FUND REGULAR GROWTH",
  "id": 104290
}, {
  "name": "WHITEOAK CAPITAL MID CAP FUND REGULAR PLAN - GROWTH",
  "id": 104309
}, {
  "name": "WHITEOAK CAPITAL ELSS TAX SAVER FUND REGULAR PLAN - GROWTH",
  "id": 104311
}, {
  "name": "WHITEOAK CAPITAL FLEXI CAP FUND REGULAR PLAN - GROWTH",
  "id": 104312
}, {
  "name": "WHITEOAK CAPITAL LARGE CAP FUND REGULAR PLAN - GROWTH",
  "id": 104319
}, {
  "name": "ITI BANKING AND FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
  "id": 104340
}, {
  "name": "ITI MULTI CAP FUND REGULAR PLAN GROWTH",
  "id": 104362
}, {
  "name": "ITI PHARMA AND HEALTHCARE FUND - REGULAR PLAN - GROWTH",
  "id": 104374
}, {
  "name": "QUANT ESG EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 104398
}, {
  "name": "QUANT ACTIVE FUND REGULAR PLAN - GROWTH",
  "id": 104404
}, {
  "name": "QUANT LARGE CAP FUND-REGULAR PLAN-GROWTH",
  "id": 104430
}, {
  "name": "QUANT FLEXI CAP FUND REGULAR PLAN - GROWTH",
  "id": 104448
}, {
  "name": "QUANT QUANTAMENTAL FUND REGULAR PLAN - GROWTH",
  "id": 104454
}, {"name": "QUANT VALUE FUND - REGULAR PLAN GROWTH", "id": 104464}, {
  "name": "NJ ARBITRAGE FUND REGULAR PLAN - GROWTH",
  "id": 104466
}, {
  "name": "NJ BALANCED ADVANTAGE FUND REGULAR PLAN - GROWTH",
  "id": 104470
}, {
  "name": "SAMCO ELSS TAX SAVER FUND-REGULAR GROWTH",
  "id": 104472
}, {
  "name": "SAMCO FLEXI CAP FUND - REGULAR PLAN - GROWTH",
  "id": 104474
}, {
  "name": "TATA ETHICAL FUND REGULAR PLAN GROWTH",
  "id": 104502
}, {
  "name": "TATA INFRASTRUCTURE TAX SAVING FUND REGULAR PLAN GROWTH",
  "id": 104505
}, {
  "name": "TATA BUSINESS CYCLE FUND -REGULAR PLAN - GROWTH",
  "id": 104528
}, {
  "name": "TATA DIGITAL INDIA FUND REGULAR PLAN GROWTH",
  "id": 104553
}, {
  "name": "TATA NIFTY INDIA DIGITAL ETF FUND OF FUND REGULAR PLAN GROWTH",
  "id": 104583
}, {
  "name": "TATA HOUSING OPPORTUNITIES FUND - REGULAR PLAN GROWTH",
  "id": 104596
}, {
  "name": "TATA NIFTY MIDCAP 150 MOMENTUM 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 104655
}, {
  "name": "TATA QUANT FUND REGULAR PLAN GROWTH",
  "id": 104668
}, {
  "name": "TATA RESOURCES AND ENERGY FUND REGULAR PLAN GROWTH",
  "id": 104676
}, {
  "name": "TATA DIVIDEND YIELD FUND - REGULAR PLAN GROWTH",
  "id": 104704
}, {
  "name": "ADITYA BIRLA SUN LIFE MULTI ASSET ALLOCATION FUND- REGULAR PLAN GROWTH",
  "id": 104801
}, {
  "name": "HDFC SILVER ETF FUND OF FUND REGULAR GROWTH",
  "id": 104844
}, {
  "name": "UTI LONG TERM ADVANTAGE FUND - SERIES III - REGULAR PLAN - GROWTH PLAN",
  "id": 104866
}, {
  "name": "UTI LONG TERM ADVANTAGE FUND SERIES IV - REGULAR GROWTH PLAN",
  "id": 104870
}, {
  "name": "UTI LONG TERM ADVANTAGE FUND SERIES V - REGULAR GROWTH PLAN",
  "id": 104874
}, {
  "name": "UTI LONG TERM ADVANTAGE FUND SERIES VI - REGULAR GROWTH PLAN",
  "id": 104878
}, {
  "name": "UTI LONG TERM ADVANTAGE FUND SERIES VII - REGULAR PLAN - GROWTH",
  "id": 104882
}, {
  "name": "BANDHAN ASSET ALLOCATION FUND OF FUND-MODERATE PLAN-REGULAR PLAN-GROWTH",
  "id": 104920
}, {
  "name": "BANK OF INDIA EQUITY FUND ECO PLAN - GROWTH",
  "id": 104925
}, {
  "name": "BANK OF INDIA REGULAR RETURN FUNDECO PLAN - GROWTH",
  "id": 104928
}, {
  "name": "BANK OF INDIA MIDCAP TAX FUND - SERIES 2 - REGULAR PLAN - GROWTH",
  "id": 104936
}, {
  "name": "HSBC CRISIL IBX 50:50 GILT PLUS SDL APR 2028 INDEX FUND GROWTH",
  "id": 104971
}, {
  "name": "HSBC MULTI CAP FUND REGULAR PLAN - GROWTH",
  "id": 104988
}, {
  "name": "TATA MULTICAP FUND - REGULAR PLAN - GROWTH",
  "id": 105025
}, {
  "name": "UTI NIFTY SDL PLUS AAA PSU BOND APR 2026 75:25 INDEX FUND -REGULAR PLAN-GROWTH",
  "id": 105038
}, {
  "name": "WHITEOAK CAPITAL BALANCED ADVANTAGE FUND - REGULAR PLAN GROWTH",
  "id": 105048
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX SDL JUN 2032 INDEX FUND -REGULAR PLAN-GROWTH",
  "id": 105062
}, {
  "name": "BANDHAN CRISIL IBX GILT APRIL 2032 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 105070
}, {
  "name": "KOTAK NIFTY SDL JUL 2033 INDEX FUND -REGULAR PLAN-GROWTH",
  "id": 105158
}, {
  "name": "ITI FLEXI CAP FUND - REGULAR PLAN - GROWTH",
  "id": 105184
}, {
  "name": "MIRAE ASSET FLEXI CAP FUND REGULAR PLAN GROWTH",
  "id": 105221
}, {
  "name": "PGIM INDIA CRISIL IBX GILT INDEX - APR 2028 FUND REGULAR PLAN - GROWTH",
  "id": 105233
}, {
  "name": "AXIS BUSINESS CYCLES FUND REGULAR GROWTH",
  "id": 105245
}, {
  "name": "HDFC NIFTY SDL OCT 2026 INDEX FUND REGULAR GROWTH",
  "id": 105261
}, {
  "name": "AXIS CRISIL IBX 50:50 GILT PLUS SDL SEPTEMBER 2027 INDEX FUND REGULAR PLAN GROWTH",
  "id": 105273
}, {
  "name": "BANK OF INDIA MULTICAP FUND REGULAR PLAN - GROWTH",
  "id": 105326
}, {
  "name": "KOTAK BANKING AND FINANCIAL SERVICES FUND-REGULAR PLAN-GROWTH",
  "id": 105328
}, {
  "name": "UTI NIFTY SDL PLUS AAA PSU BOND APR 2028 75:25 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 105360
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX GILT APR 2028 INDEX FUND - REGULAR GROWTH",
  "id": 105406
}, {
  "name": "HDFC MNC FUND REGULAR PLAN GROWTH",
  "id": 105432
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY SDL SEP 2027 INDEX FUND- REGULAR PLAN GROWTH",
  "id": 105440
}, {
  "name": "HDFC NIFTY G-SEC APR 2029 INDEX FUND REGULAR PLAN GROWTH",
  "id": 105482
}, {
  "name": "SBI DIVIDEND YIELD FUND - REGULAR PLAN - GROWTH",
  "id": 105522
}, {
  "name": "HDFC NIFTY G- SEC JUN 2036 INDEX FUND REGULAR PLAN GROWTH",
  "id": 105542
}, {
  "name": "UTI LONG DURATION FUND-REGULAR PLAN-GROWTH",
  "id": 105568
}, {
  "name": "INVESCO INDIA NIFTY G-SEC JUL 2027 INDEX FUND REGULAR PLAN GROWTH",
  "id": 105610
}, {
  "name": "HSBC CRISIL IBX GILT JUNE 2027 INDEX FUND - REGULAR PLAN GROWTH",
  "id": 105786
}, {
  "name": "BARODA BNP PARIBAS NIFTY SDL DECEMBER 2028 INDEX FUND REGULAR PLAN GROWTH",
  "id": 105796
}, {
  "name": "NAVI ELSS TAX SAVER NIFTY 50 INDEX FUND-REGULAR PLAN GROWTH",
  "id": 105802
}, {
  "name": "HDFC NIFTY SDL PLUS G-SEC JUN 2027 40:60 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 105813
}, {
  "name": "INVESCO INDIA NIFTY G-SEC SEP 2032 INDEX FUND REGULAR PLAN GROWTH",
  "id": 105844
}, {
  "name": "MIRAE ASSET NIFTY SDL JUNE 2028 INDEX FUND REGULAR PLAN GROWTH",
  "id": 105860
}, {
  "name": "KOTAK SILVER ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 105905
}, {
  "name": "QUANT DYNAMIC ASSET ALLOCATION FUND - REGULAR PLAN GROWTH",
  "id": 105919
}, {
  "name": "HDFC NIFTY MIDCAP 150 INDEX FUND - REGULAR - GROWTH",
  "id": 105930
}, {
  "name": "HDFC NIFTY SMALLCAP 250 INDEX FUND - REGULAR - GROWTH",
  "id": 105934
}, {
  "name": "HDFC BSE 500 INDEX FUND - REGULAR - GROWTH",
  "id": 105938
}, {
  "name": "ICICI PRUDENTIAL INNOVATION FUND REGULAR PLAN GROWTH",
  "id": 105946
}, {
  "name": "UTI SILVER ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 105972
}, {
  "name": "KOTAK NIFTY SMALLCAP 50 INDEX FUND - REGULAR PLAN GROWTH",
  "id": 105974
}, {
  "name": "NIPPON INDIA NIFTY G-SEC- SEP 2027 MATURITY INDEX FUND GROWTH PLAN",
  "id": 106009
}, {
  "name": "NIPPON INDIA NIFTY G-SEC OCT 2028 MATURITY INDEX FUND GROWTH PLAN",
  "id": 106015
}, {
  "name": "UTI NIFTY 500 VALUE 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 106030
}, {
  "name": "BANDHAN US TREASURY BOND 0-1 YEAR FUND OF FUND REGULAR PLAN-GROWTH",
  "id": 106074
}, {
  "name": "DSP NIFTY SDL PLUS G-SEC SEP 2027 50:50 INDEX FUND - REGULAR - GROWTH",
  "id": 106082
}, {
  "name": "SBI BSE SENSEX INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 106116
}, {
  "name": "QUANT BUSINESS CYCLE FUND - REGULAR PLAN GROWTH - GROWTH",
  "id": 106140
}, {
  "name": "HDFC DEFENCE FUND REGULAR PLAN GROWTH",
  "id": 106169
}, {
  "name": "BANK OF INDIA MIDCAP TAX FUND - SERIES 1 REGULAR PLAN - GROWTH",
  "id": 106174
}, {
  "name": "UTI NIFTY 50 EQUAL WEIGHT INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 106177
}, {
  "name": "UTI BSE HOUSING INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 106181
}, {
  "name": "BARODA BNP PARIBAS VALUE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 106193
}, {
  "name": "NJ ELSS TAX SAVER SCHEME REGULAR PLAN - GROWTH",
  "id": 106229
}, {
  "name": "ITI FOCUSED EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 106246
}, {
  "name": "QUANT BFSI FUND - REGULAR PLAN GROWTH - GROWTH",
  "id": 106258
}, {
  "name": "KOTAK NIFTY 200 MOMENTUM 30 INDEX FUND -REGULAR PLAN-GROWTH",
  "id": 106262
}, {
  "name": "EDELWEISS MULTI ASSET ALLOCATION FUND- REGULAR PLAN GROWTH",
  "id": 106301
}, {
  "name": "360 ONE FLEXICAP FUND - REGULAR PLAN - GROWTH",
  "id": 106366
}, {
  "name": "BAJAJ FINSERV LIQUID FUND - REGULAR PLAN GROWTH",
  "id": 106394
}, {
  "name": "BAJAJ FINSERV OVERNIGHT FUND - REGULAR PLAN GROWTH",
  "id": 106426
}, {
  "name": "MOTILAL OSWAL NIFTY MICROCAP 250 INDEX FUND REGULAR PLAN GROWTH",
  "id": 106452
}, {
  "name": "SAMCO ACTIVE MOMENTUM FUND -REGULAR PLAN-GROWTH",
  "id": 106481
}, {
  "name": "QUANT HEALTHCARE FUND - REGULAR PLAN GROWTH - GROWTH",
  "id": 106508
}, {
  "name": "HDFC NON-CYCLICAL CONSUMER FUND REGULAR PLAN GROWTH",
  "id": 106524
}, {
  "name": "AXIS NIFTY IT INDEX FUND REGULAR PLAN GROWTH",
  "id": 106536
}, {
  "name": "NAVI OVERNIGHT FUND - REGULAR PLAN GROWTH",
  "id": 106556
}, {
  "name": "BAJAJ FINSERV MONEY MARKET FUND - REGULAR PLAN - GROWTH",
  "id": 106621
}, {
  "name": "UTI RETIREMENT FUND -REGULAR PLAN- GROWTH",
  "id": 106885
}, {
  "name": "WHITEOAK CAPITAL MULTI ASSET ALLOCATION FUND - REGULAR PLAN GROWTH",
  "id": 106894
}, {
  "name": "LIC DIVIDEND YIELD FUND - REGULAR PLAN - GROWTH",
  "id": 106942
}, {
  "name": "BANDHAN FINANCIAL SERVICES FUND REGULAR PLAN-GROWTH",
  "id": 107072
}, {
  "name": "CANARA ROBECO MULTI CAP FUND REGULAR PLAN GROWTH",
  "id": 107099
}, {
  "name": "KOTAK QUANT FUND - REGULAR PLAN-GROWTH",
  "id": 107115
}, {
  "name": "QUANT MANUFACTURING FUND - REGULAR PLAN - GROWTH",
  "id": 107163
}, {
  "name": "BAJAJ FINSERV FLEXI CAP FUND REGULAR PLAN - GROWTH",
  "id": 107175
}, {
  "name": "UTI BALANCED ADVANTAGE FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 107184
}, {
  "name": "MIRAE ASSET MULTICAP FUND REGULAR PLAN - GROWTH",
  "id": 107221
}, {
  "name": "KOTAK NIFTY FINANCIAL SERVICES EX - BANK INDEX FUND - REGULAR PLAN-GROWTH",
  "id": 107229
}, {
  "name": "HDFC TRANSPORTATION AND LOGISTICS FUND REGULAR PLAN GROWTH",
  "id": 107259
}, {
  "name": "BANDHAN NIFTY IT INDEX FUND REGULAR PLAN-GROWTH",
  "id": 107279
}, {
  "name": "NIPPON INDIA INNOVATION FUND REGULAR PLAN GROWTH",
  "id": 107311
}, {
  "name": "KOTAK BSE HOUSING INDEX FUND -REGULAR PLAN-GROWTH",
  "id": 107313
}, {
  "name": "HSBC CONSUMPTION FUND - REGULAR PLAN GROWTH",
  "id": 107341
}, {
  "name": "NJ FLEXI CAP FUND - REGULAR PLAN GROWTH",
  "id": 107351
}, {
  "name": "DSP MULTI ASSET ALLOCATION FUND - REGULAR - GROWTH",
  "id": 107353
}, {
  "name": "NAVI BSE SENSEX INDEX FUND REGULAR PLAN - GROWTH",
  "id": 107375
}, {
  "name": "QUANT TECK FUND - REGULAR PLAN GROWTH",
  "id": 107395
}, {
  "name": "MAHINDRA MANULIFE BUSINESS CYCLE FUND - REGULAR PLAN GROWTH",
  "id": 107409
}, {
  "name": "UNION INNOVATION OPP FUND - REGULAR PLAN GROWTH",
  "id": 107423
}, {
  "name": "HDFC TECHNOLOGY FUND - REGULAR PLAN GROWTH",
  "id": 107439
}, {
  "name": "HDFC PHARMA AND HEALTHCARE FUND REGULAR PLAN GROWTH",
  "id": 107448
}, {
  "name": "SHRIRAM MULTI ASSET ALLOCATION FUND REGULAR PLAN -  GROWTH",
  "id": 107449
}, {
  "name": "BAJAJ FINSERV ARBITRAGE FUND REGULAR PLAN - GROWTH",
  "id": 107463
}, {
  "name": "UTI INNOVATION FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 107470
}, {
  "name": "WHITEOAK CAPITAL MULTI CAP FUND - REGULAR GROWTH",
  "id": 107479
}, {
  "name": "MOTILAL OSWAL DEVELOPED MARKET EX US ETFS FUND OF FUNDS - REGULAR PLAN - GROWTH",
  "id": 107485
}, {
  "name": "KOTAK MULTI ASSET ALLOCATION FUND -REGULAR PLAN-GROWTH",
  "id": 107543
}, {
  "name": "360 ONE BALANCED HYBRID FUND - REGULAR PLAN - GROWTH",
  "id": 107565
}, {
  "name": "KOTAK NIFTY G-SEC JULY 2033 INDEX FUND -REGULAR PLAN-GROWTH",
  "id": 107617
}, {
  "name": "BANDHAN RETIREMENT FUND REGULAR PLAN-GROWTH",
  "id": 107656
}, {
  "name": "KOTAK CONSUMPTION FUND -REGULAR PLAN-GROWTH",
  "id": 107706
}, {
  "name": "GROWW NIFTY TOTAL MARKET INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 107724
}, {
  "name": "HELIOS OVERNIGHT FUND-REGULAR PLAN-GROWTH",
  "id": 107732
}, {
  "name": "ADITYA BIRLA SUN LIFE TRANSPORTATION AND LOGISTICS FUND-REGULAR PLAN-GROWTH",
  "id": 107739
}, {
  "name": "EDELWEISS MULTI CAP FUND - REGULAR PLAN- GROWTH",
  "id": 107752
}, {
  "name": "WHITEOAK CAPITAL BALANCED HYBRID FUND - REGULAR PLAN - GROWTH",
  "id": 107754
}, {
  "name": "BARODA BNP PARIBAS SMALL CAP FUND - REGULAR PLAN - GROWTH OPTION",
  "id": 107776
}, {
  "name": "ADITYA BIRLA SUN LIFE US TREASURY 1-3 YEAR BOND ETFS FUND OF FUNDS-REGULAR PLAN-GROWTH",
  "id": 107782
}, {
  "name": "ADITYA BIRLA SUN LIFE US TREASURY 3-10 YEAR BOND ETFS FUND OF FUNDS-REGULAR PLAN-GROWTH",
  "id": 107798
}, {
  "name": "PARAG PARIKH ARBITRAGE FUND-REGULAR PLAN-GROWTH",
  "id": 107822
}, {
  "name": "NIPPON INDIA NIFTY AAA CPSE BOND PLUS SDL - APR 2027 MATURITY 60:40 INDEX FUND-GROWTH",
  "id": 107838
}, {
  "name": "NIPPON INDIA NIFTY AAA PSU BOND PLUS SDL - SEP 2026 MATURITY 50:50 INDEX FUND GROWTH PLAN",
  "id": 107844
}, {
  "name": "NIPPON INDIA NIFTY SDL PLUS G-SEC-JUN 2028 MATURITY 70:30 INDEX FUND GROWTH",
  "id": 107850
}, {
  "name": "NIPPON INDIA NIVESH LAKSHYA FUND - GROWTH PLAN GROWTH",
  "id": 107866
}, {
  "name": "NIPPON INDIA NIFTY SDL PLUS G-SEC- JUN 2029 MATURITY 70:30 INDEX FUND GROWTH",
  "id": 107878
}, {
  "name": "NAVI NASDAQ 100 FUND OF FUND - REGULAR PLAN GROWTH",
  "id": 107882
}, {
  "name": "NAVI US TOTAL STOCK MARKET FUND OF FUND - REGULAR PLAN GROWTH",
  "id": 107884
}, {
  "name": "QUANTUM SMALL CAP FUND- REGULAR PLAN GROWTH",
  "id": 107889
}, {
  "name": "BANDHAN NIFTY ALPHA 50 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 107891
}, {
  "name": "BAJAJ FINSERV BANKING AND PSU FUND REGULAR PLAN - GROWTH",
  "id": 107945
}, {
  "name": "HELIOS FLEXI CAP FUND-REGULAR PLAN-GROWTH",
  "id": 107963
}, {
  "name": "QUANT MOMENTUM FUND - REGULAR PLAN GROWTH - GROWTH",
  "id": 108023
}, {
  "name": "DSP GOLD ETF FUND OF FUND-REGULAR PLAN-GROWTH",
  "id": 108053
}, {
  "name": "AXIS INDIA MANUFACTURING FUND-REGULAR PLAN-GROWTH",
  "id": 108076
}, {
  "name": "DSP NIFTY SMALLCAP250 QUALITY 50 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 108079
}, {
  "name": "DSP BANKING & FINANCIAL SERVICES FUND-REGULAR PLAN-GROWTH",
  "id": 108127
}, {
  "name": "KOTAK HEALTHCARE FUND-REGULAR PLAN-GROWTH",
  "id": 108177
}, {
  "name": "BAJAJ FINSERV BALANCED ADVANTAGE FUND-REGULAR PLAN-GROWTH",
  "id": 108203
}, {
  "name": "UNION CHILDRENS FUND-REGULAR PLAN-GROWTH",
  "id": 108211
}, {
  "name": "BANDHAN NIFTY SMALLCAP 250 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 108213
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX GILT APRIL 2033 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 108231
}, {
  "name": "WHITEOAK CAPITAL LARGE AND MID CAP FUND-REGULAR PLAN-GROWTH",
  "id": 108241
}, {
  "name": "AXIS US TREASURY DYNAMIC BOND ETF FUND OF FUND-REGULAR PLAN-GROWTH",
  "id": 108266
}, {
  "name": "QUANT COMMODITIES FUND-REGULAR PLAN-GROWTH",
  "id": 108321
}, {
  "name": "MOTILAL OSWAL SMALL CAP FUND-REGULAR PLAN-GROWTH",
  "id": 108329
}, {
  "name": "SAMCO DYNAMIC ASSET ALLOCATION FUND-REGULAR PLAN-GROWTH",
  "id": 108371
}, {
  "name": "TATA GOLD ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 108506
}, {
  "name": "TATA SILVER ETF FUND OF FUND - REGULAR GROWTH",
  "id": 108522
}, {
  "name": "QUANT CONSUMPTION FUND REGULAR PLAN-GROWTH",
  "id": 108540
}, {
  "name": "OLD BRIDGE FOCUSED EQUITY FUND - REGULAR PLAN - GROWTH",
  "id": 108552
}, {
  "name": "BANDHAN MULTI ASSET ALLOCATION FUND-REGULAR PLAN-GROWTH",
  "id": 108560
}, {
  "name": "BARODA BNP PARIBAS NIFTY 50 INDEX FUND REGULAR PLAN-GROWTH",
  "id": 108586
}, {
  "name": "DSP MULTICAP FUND-REGULAR PLAN-GROWTH",
  "id": 108602
}, {
  "name": "MIRAE ASSET MULTI ASSET ALLOCATION FUND-REGULAR PLAN-GROWTH",
  "id": 108628
}, {
  "name": "SUNDARAM MULTI ASSET ALLOCATION FUND-REGULAR PLAN-GROWTH",
  "id": 108640
}, {
  "name": "SBI NIFTY50 EQUAL WEIGHT INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 108675
}, {
  "name": "WHITEOAK CAPITAL BANKING AND FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
  "id": 108719
}, {
  "name": "WHITEOAK CAPITAL PHARMA AND HEALTHCARE FUND - REGULAR PLAN - GROWTH",
  "id": 108721
}, {
  "name": "GROWW BANKING & FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
  "id": 108753
}, {
  "name": "MOTILAL OSWAL LARGE CAP FUND - REGULAR - GROWTH",
  "id": 108781
}, {
  "name": "ICICI PRUDENTIAL NIFTY50 VALUE 20 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 108806
}, {
  "name": "PGIM INDIA LARGE AND MID CAP FUND - REGULAR PLAN - GROWTH",
  "id": 108837
}, {
  "name": "QUANT PSU FUND - REGULAR PLAN - GROWTH",
  "id": 108866
}, {
  "name": "NIPPON INDIA NIFTY BANK INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 108890
}, {
  "name": "NIPPON INDIA NIFTY IT INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 108902
}, {
  "name": "PARAG PARIKH DYNAMIC ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 108904
}, {
  "name": "AXIS BSE SENSEX INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 108938
}, {
  "name": "BAJAJ FINSERV LARGE AND MID CAP FUND - REGULAR PLAN - GROWTH",
  "id": 108950
}, {
  "name": "SBI ENERGY OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 108958
}, {
  "name": "BANK OF INDIA MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 108978
}, {
  "name": "HSBC MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 108992
}, {
  "name": "GROWW NIFTY SMALLCAP 250 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109006
}, {
  "name": "MIRAE ASSET NIFTY SMALLCAP 250 MOMENTUM QUALITY 100 ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 109022
}, {
  "name": "HDFC NIFTY200 MOMENTUM 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109032
}, {
  "name": "BARODA BNP PARIBAS INNOVATION FUND - REGULAR PLAN - GROWTH",
  "id": 109082
}, {
  "name": "KOTAK TECHNOLOGY FUND - REGULAR PLAN - GROWTH",
  "id": 109088
}, {
  "name": "EDELWEISS TECHNOLOGY FUND - REGULAR PLAN - GROWTH",
  "id": 109114
}, {
  "name": "UNION BUSINESS CYCLE FUND - REGULAR PLAN - GROWTH",
  "id": 109136
}, {
  "name": "QUANTUM MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 109144
}, {
  "name": "KOTAK LONG DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 109146
}, {
  "name": "CANARA ROBECO MANUFACTURING FUND - REGULAR PLAN - GROWTH",
  "id": 109176
}, {
  "name": "MAHINDRA MANULIFE MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 109190
}, {
  "name": "ICICI PRUDENTIAL NIFTY LARGEMIDCAP 250 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109204
}, {
  "name": "DSP US TREASURY FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 109220
}, {
  "name": "BANDHAN LONG DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 109236
}, {
  "name": "AXIS CRISIL IBX SDL JUNE 2034 DEBT INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109266
}, {
  "name": "NAVI NIFTY IT INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 109270
}, {
  "name": "HELIOS BALANCED ADVANTAGE FUND - REGULAR PLAN - GROWTH",
  "id": 109284
}, {
  "name": "HDFC NIFTY REALTY INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109290
}, {
  "name": "KOTAK NIFTY AAA BOND JUN 2025 HTM INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109306
}, {
  "name": "PGIM INDIA RETIREMENT FUND - REGULAR PLAN - GROWTH",
  "id": 109384
}, {
  "name": "TATA NIFTY AUTO INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109416
}, {
  "name": "TRUSTMF FLEXI CAP FUND-REGULAR PLAN-GROWTH",
  "id": 109430
}, {
  "name": "TATA NIFTY500 MULTICAP INDIA MANUFACTURING 50:30:20 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109444
}, {
  "name": "TATA NIFTY MIDSMALL HEALTHCARE INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109460
}, {
  "name": "TATA NIFTY FINANCIAL SERVICES INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109476
}, {
  "name": "TATA NIFTY500 MULTICAP INFRASTRUCTURE 50:30:20 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109492
}, {
  "name": "TATA NIFTY REALTY INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109508
}, {
  "name": "BANDHAN INNOVATION FUND - REGULAR PLAN - GROWTH",
  "id": 109517
}, {
  "name": "EDELWEISS NIFTY ALPHA LOW VOLATILITY 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109604
}, {
  "name": "HDFC MANUFACTURING FUND - REGULAR PLAN - GROWTH",
  "id": 109664
}, {
  "name": "GROWW NIFTY NON-CYCLICAL CONSUMER INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109688
}, {
  "name": "AXIS NIFTY BANK INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109758
}, {
  "name": "HSBC ASIA PACIFIC(EX JAPAN) DIVIDEND YIELD FUND GROWTH",
  "id": 109764
}, {"name": "HSBC BRAZIL FUND - GROWTH", "id": 109767}, {
  "name": "HSBC GLOBAL EMERGING MARKETS FUND - GROWTH",
  "id": 109771
}, {
  "name": "HSBC GLOBAL EQUITY CLIMATE CHANGE FUND OF FUND GROWTH",
  "id": 109775
}, {
  "name": "MIRAE ASSET NIFTY MIDSMALLCAP400 MOMENTUM QUALITY 100 ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 109802
}, {
  "name": "BARODA BNP PARIBAS RETIREMENT FUND - REGULAR PLAN - GROWTH",
  "id": 109820
}, {
  "name": "DSP NIFTY BANK INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109902
}, {
  "name": "WHITEOAK CAPITAL SPECIAL OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 109936
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL IBX GILT JUNE 2027 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 109968
}, {
  "name": "BAJAJ FINSERV MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 109994
}, {
  "name": "SBI AUTOMOTIVE OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 110052
}, {
  "name": "KOTAK NIFTY 100 LOW VOLATILITY 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 110066
}, {
  "name": "SAMCO SPECIAL OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 110090
}, {
  "name": "MOTILAL OSWAL MULTI CAP FUND - REGULAR PLAN - GROWTH",
  "id": 110160
}, {
  "name": "JM SMALL CAP FUND - REGULAR PLAN - GROWTH",
  "id": 110182
}, {
  "name": "HELIOS FINANCIAL SERVICES FUND - REGULAR PLAN - GROWTH",
  "id": 110196
}, {
  "name": "MAHINDRA MANULIFE MANUFACTURING FUND - REGULAR PLAN - GROWTH",
  "id": 110214
}, {
  "name": "ADITYA BIRLA SUN LIFE QUANT FUND - REGULAR PLAN - GROWTH",
  "id": 110229
}, {
  "name": "SUNDARAM BUSINESS CYCLE FUND - REGULAR PLAN - GROWTH",
  "id": 110245
}, {
  "name": "BARODA BNP PARIBAS MANUFACTURING FUND - REGULAR PLAN - GROWTH",
  "id": 110267
}, {
  "name": "KOTAK SPECIAL OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 110269
}, {
  "name": "MOTILAL OSWAL NIFTY INDIA DEFENCE INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 110387
}, {
  "name": "BANDHAN NIFTY TOTAL MARKET INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 110459
}, {
  "name": "HDFC NIFTY100 LOW VOLATILITY 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 110655
}, {
  "name": "SBI SILVER ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 110663
}, {
  "name": "AXIS NIFTY 500 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 110689
}, {
  "name": "ICICI PRUDENTIAL ENERGY OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 110747
}, {
  "name": "TATA NIFTY INDIA TOURISM INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 110781
}, {
  "name": "MIRAE ASSET NIFTY200 ALPHA 30 ETF FUND OF FUND - REGULAR PLAN - GROWTH",
  "id": 110813
}, {
  "name": "FRANKLIN INDIA MULTICAP FUND -REGULAR PLAN-GROWTH",
  "id": 110911
}, {
  "name": "EDELWEISS BUSINESS CYCLE FUND - REGULAR PLAN - GROWTH",
  "id": 110963
}, {
  "name": "KOTAK BSE PSU INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 111009
}, {
  "name": "NAVI NIFTY 500 MULTICAP 50:25:25 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 111027
}, {
  "name": "MOTILAL OSWAL MANUFACTURING FUND - REGULAR PLAN - GROWTH",
  "id": 111213
}, {
  "name": "GROWW NIFTY EV & NEW AGE AUTOMOTIVE ETF FOF - REGULAR PLAN - GROWTH",
  "id": 111229
}, {
  "name": "INVESCO INDIA MANUFACTURING FUND - REGULAR PLAN - GROWTH",
  "id": 111261
}, {
  "name": "KOTAK NIFTY MIDCAP 50 INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 111277
}, {
  "name": "CANARA ROBECO BALANCED ADVANTAGE FUND - REGULAR PLAN - GROWTH",
  "id": 111364
}, {
  "name": "BAJAJ FINSERV LARGE CAP FUND - REGULAR PLAN - GROWTH",
  "id": 111429
}, {
  "name": "SBI INNOVATIVE OPPORTUNITIES FUND - REGULAR PLAN - GROWTH",
  "id": 111749
}, {
  "name": "BANDHAN NIFTY BANK INDEX FUND-REGULAR PLAN-GROWTH",
  "id": 111843
}, {
  "name": "HDFC NIFTY500 MULTICAP 50:25:25 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 112007
}, {
  "name": "FRANKLIN INDIA ULTRA SHORT DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 112162
}, {
  "name": "BANK OF INDIA BUSINESS CYCLE FUND - REGULAR PLAN - GROWTH",
  "id": 112356
}, {
  "name": "MOTILAL OSWAL BUSINESS CYCLE FUND - REGULAR PLAN - GROWTH",
  "id": 112396
}, {
  "name": "ADITYA BIRLA SUN LIFE NIFTY INDIA DEFENCE INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 112888
}, {
  "name": "ICICI PRUDENTIAL GLOBAL ADVANTAGE FUND (FOF) GROWTH-INSURED",
  "id": 112896
}, {
  "name": "BANDHAN BSE HEALTHCARE INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113089
}, {
  "name": "DSP NIFTY TOP 10 EQUAL WEIGHT INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113093
}, {
  "name": "WHITEOAK CAPITAL ARBITRAGE FUND - REGULAR PLAN - GROWTH",
  "id": 113153
}, {
  "name": "TATA NIFTY200 ALPHA 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113169
}, {
  "name": "NIPPON INDIA NIFTY 500 EQUAL WEIGHT INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113189
}, {
  "name": "PGIM INDIA MULTI CAP FUND - REGULAR PLAN - GROWTH",
  "id": 113205
}, {
  "name": "UNION MULTI ASSET ALLOCATION FUND - REGULAR PLAN - GROWTH",
  "id": 113217
}, {
  "name": "ITI LARGE & MID CAP FUND - REGULAR PLAN - GROWTH",
  "id": 113229
}, {
  "name": "MOTILAL OSWAL QUANT FUND REGULAR PLAN GROWTH",
  "id": 113274
}, {
  "name": "BARODA BNP PARIBAS DIVIDEND YIELD FUND - REGULAR PLAN - GROWTH",
  "id": 113339
}, {
  "name": "AXIS CONSUMPTION FUND - REGULAR PLAN - GROWTH",
  "id": 113389
}, {
  "name": "BANDHAN NIFTY MIDCAP 150 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113438
}, {
  "name": "KOTAK CRISIL-IBX AAA FINANCIAL SERVICES INDEX SEP 2027 FUND - REGULAR PLAN - GROWTH",
  "id": 113507
}, {
  "name": "UTI NIFTY200 QUALITY 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113535
}, {
  "name": "UTI NIFTY PRIVATE BANK INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113539
}, {
  "name": "BARODA BNP PARIBAS NIFTY 200 MOMENTUM 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113552
}, {
  "name": "INVESCO INDIA TECHNOLOGY FUND - REGULAR PLAN - GROWTH",
  "id": 113569
}, {
  "name": "FRANKLIN INDIA MEDIUM TO LONG DURATION FUND - REGULAR PLAN - GROWTH",
  "id": 113573
}, {
  "name": "AXIS CRISIL-IBX AAA NBFC INDEX - JUN 2027 FUND REGULAR PLAN GROWTH",
  "id": 113609
}, {
  "name": "ICICI PRUDENTIAL NIFTY200 VALUE 30 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113629
}, {
  "name": "KOTAK NIFTY INDIA TOURISM INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113639
}, {
  "name": "MOTILAL OSWAL NIFTY 500 MOMENTUM 50 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113657
}, {
  "name": "HSBC INDIA EXPORT OPPORTUNITIES FUND-REGULAR PLAN GROWTH",
  "id": 113671
}, {
  "name": "BANDHAN BUSINESS CYCLE FUND - REGULAR PLAN-GROWTH",
  "id": 113688
}, {
  "name": "NIPPON INDIA NIFTY 500 MOMENTUM 50 INDEX FUND - REGULAR  PLAN- GROWTH",
  "id": 113723
}, {
  "name": "SBI NIFTY 500 INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113743
}, {
  "name": "AXIS NIFTY500 VALUE 50 INDEX FUND - REGULAR PLAN GROWTH",
  "id": 113756
}, {
  "name": "TATA NIFTY CAPITAL MARKETS INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113775
}, {
  "name": "KOTAK MNC FUND-REGULAR PLAN-GROWTH",
  "id": 113779
}, {
  "name": "ADITYA BIRLA SUN LIFE CRISIL-IBX AAA NBFC-HFC INDEX \u2013DEC 2025 FUND - REGULAR PLAN - GROWTH",
  "id": 113806
}, {
  "name": "MIRAE ASSET NIFTY TOTAL MARKET INDEX FUND - REGULAR PLAN - GROWTH",
  "id": 113816
}, {"name": "BANDHAN NIFTY BANK INDEX FUND-REGULAR PLAN-GROWTH", "id": 113819}];