// components =============================
import React, { useEffect, useState } from 'react'
import Header from "../../Molecules/Header/Header"
import Footer from "../../Molecules/Footer/Footer"
import { Helmet } from 'react-helmet'
// components ends ========================

// scss =============================
import "./Login.scss"
// scss ends ========================

// images =============================
import mascot from "../../Images/WFS Mascot.png"
// images ends ========================

const Login = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [clas, setClas] = useState("container");
    const [right, setDisplayRight] = useState("none");
    const [left, setDisplayLeft] = useState("block");
    // const [transition, setTransition] = useState("");
    // const [transform, setTransform] = useState("");


    const register = () => {
        setClas('container active');
        setDisplayRight("block");
        setDisplayLeft("none");

        // setRight("65%");
        // setTransition(".5s all ease");
        // setTransform("ScaleX(-1)")
    }

    const login = () => {
        setClas('container');
        setDisplayRight("none");
        setDisplayLeft("block");
        // setRight("15%");
        // setTransition(".5s all ease");
        // setTransform("ScaleX(1)")
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Client Login | Secure Access to Your Financial Portfolio |Wise Funds
                </title>
                <link rel="canonical" href="https://www.wisefunds.com/login" />
                <meta name="description" content="Login securely to access your financial portfolio with Wise Funds. Manage your investments, track performance, and stay updated on your financial goals." />
                <meta name="keywords" content="Client login, secure access, financial portfolio, investment tracking, Wise Funds login, portfolio management, secure financial access, track your stocks, track your goals" />
            </Helmet>
            <Header />
            <div className="login" >
                {/* <img style={{ right: right, transition: transition, transform: transform }} className='mascot' src={mascot} alt="mascot" /> */}

                <div class={clas} >
                    <div class="curved-shape"></div>
                    <div class="curved-shape2"></div>
                    <div class="form-box Login">
                        <img style={{ display: left }} className='mascot' src={mascot} alt="mascot" />
                        <h2 class="animation">Login</h2>
                        <form action="#">
                            <div class="input-box animation">
                                <input type="text" required />
                                <label for="">Username</label>
                                <box-icon type='solid' name='user'></box-icon>
                            </div>

                            <div class="input-box animation">
                                <input type="password" required />
                                <label for="">Password</label>
                                <box-icon name='lock-alt' type='solid' ></box-icon>
                            </div>

                            <div class="input-box animation">
                                <button class="login-btn" type="submit">Login</button>
                            </div>

                            <div class="regi-link animation">
                                <p>Don't have an account? <br /> <a href="#" class="SignUpLink" onClick={register}>Sign Up</a></p>
                            </div>
                        </form>
                    </div>
                    <div class="form-box Register">
                        <img style={{ display: right }} className='mascot' src={mascot} alt="mascot" />
                        <h2 class="animation">Register</h2>
                        <form action="#">
                            <div class="input-box animation">
                                <input type="text" required />
                                <label for="">Username</label>
                                <box-icon type='solid' name='user'></box-icon>
                            </div>

                            <div class="input-box animation">
                                <input type="email" required />
                                <label for="">Email</label>
                                <box-icon name='envelope' type='solid' ></box-icon>
                            </div>

                            <div class="input-box animation">
                                <input type="password" required />
                                <label for="">Password</label>
                                <box-icon name='lock-alt' type='solid' ></box-icon>
                            </div>

                            <div class="input-box animation">
                                <button class="login-btn" type="submit">Register</button>
                            </div>

                            <div class="regi-link animation">
                                <p>Don't have an account? <br /> <a href="#" class="SignInLink" onClick={login}>Sign In</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Login