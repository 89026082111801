/*
import React, { useState } from 'react';
import {FaSearch, FaStar, FaTimes} from 'react-icons/fa';
import axios from 'axios';

function MutualFunds() {
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedFundId, setSelectedFundId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle search input change and update suggestions
  const handleSearchChange = async (event) => {
    const value = event.target.value;
    setSearchInput(value);

    if (value.length >= 2) {
      setLoading(true);
      try {
        const response = await axios.get(`https://www.wisefunds.in/app/rest/mutualfund/fetch?query=${value}`);
        if (response.data.success) {
          setSuggestions(response.data.data.slice(0, 6)); // Limit results to top 6
        }
      } catch (error) {
        console.error('Error fetching mutual funds:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setSuggestions([]);
    }
  };

  // Handle suggestion selection and navigate directly to the fund
  const handleSuggestionClick = (fund) => {
    setSearchInput(fund.name);
    setSelectedFundId(fund.id);
    setSuggestions([]);
    window.open(`https://www.wisefunds.in/mf/index.jsp?id=${fund.id}`, '_blank'); // Open the fund page directly
  };

  // Clear the search input and suggestions
  const clearSearch = () => {
    setSearchInput('');
    setSuggestions([]);
    setSelectedFundId(null);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '400px', margin: 'auto' }}>
        {/!* Search Icon *!/}
        <FaSearch style={{ position: 'absolute', left: '10px', color: '#ccc', fontSize: '18px' }} />

        <input
          type="text"
          placeholder="Search mutual funds..."
          value={searchInput}
          onChange={handleSearchChange}
          className={'search-bar'}
          style={{
            width: '100%',
            padding: '10px 10px 10px 35px', // Padding to accommodate the search icon
            borderRadius: '24px',
            border: '2px solid #ccc',
            fontSize: '16px',
            boxSizing: 'border-box',
            outline: 'none',
          }}
        />

        {/!* Clear Button *!/}
        {searchInput && (
          <FaTimes
            onClick={clearSearch}
            style={{
              position: 'absolute',
              right: '10px',
              color: '#ccc',
              fontSize: '18px',
              cursor: 'pointer',
              backgroundColor: 'white'
            }}
          />
        )}
      </div>

      {/!* Suggestions dropdown *!/}
      {suggestions.length > 0 && (
        <ul style={{
          listStyle: 'none',
          padding: 0,
          margin: '10px 0',
          border: '1px solid #ccc',
          borderRadius: '4px',
          maxWidth: '500px',
          marginLeft: 'auto',
          marginRight: 'auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          position: 'absolute',
          zIndex: '9999',
          backgroundColor: '#fff',
          maxHeight: '400px', // Set maximum height for 5-6 items
          overflowY: 'auto',  // Enable vertical scroll
        }}>
          {suggestions.map(fund => (
            <li
              key={fund.id}
              onClick={() => handleSuggestionClick(fund)}
              style={{
                display:"flex",
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid #eee',
                backgroundColor: '#fff',
                transition: 'background-color 0.2s ease',
              }}
            >
              <img
                src={fund.fundhouse.logoUrl}
                alt={`${fund.fundhouse.name} logo`}
                style={{ width: '40px', height: '40px', marginRight: '10px' }}
              />
              <div style={{ flex: 1 }}>
                <div style={{ fontWeight: 'bold' }}>{fund.name}</div>
                <div style={{ color: '#ccc' }}>{fund.fundhouse.name}</div>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  {[...Array(5)].map((_, index) => (
                    <FaStar
                      key={index}
                      style={{ color: index < fund.rating ? '#ffd700' : '#ccc', fontSize: '16px' }}
                    />
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default MutualFunds;
*/

import React, { useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import {MutualFundsDetails} from "./Services";

function MutualFunds() {
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedFundId, setSelectedFundId] = useState(null);

  // Handle search input change and update suggestions
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    if (value.length >= 2) {
      const filteredFunds = MutualFundsDetails
        .filter(fund => fund.name.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 10); // Limit results to top 10
      setSuggestions(filteredFunds);
    } else {
      setSuggestions([]);
    }
  };

  // Handle suggestion selection and navigate directly to the fund
  const handleSuggestionClick = (fund) => {
    setSearchInput(fund.name);
    setSelectedFundId(fund.id);
    setSuggestions([]);
    window.open(`https://www.wisefunds.in/mf/index.jsp?id=${fund.id}`, '_blank'); // Open the fund page directly
  };

  // Clear the search input and suggestions
  const clearSearch = () => {
    setSearchInput('');
    setSuggestions([]);
    setSelectedFundId(null);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '400px', margin: 'auto' }}>
        {/* Search Icon */}
        <FaSearch style={{ position: 'absolute', left: '10px', color: '#ccc', fontSize: '18px' }} />

        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchChange}
          className={'search-bar'}
          style={{
            width: '100%',
            padding: '10px 10px 10px 35px', // Padding to accommodate the search icon
            borderRadius: '24px',
            border: '2px solid #ccc',
            fontSize: '16px',
            boxSizing: 'border-box',
            outline: 'none',
          }}
        />

        {/* Clear Button */}
        {searchInput && (
          <FaTimes
            onClick={clearSearch}
            style={{
              position: 'absolute',
              right: '10px',
              color: '#ccc',
              fontSize: '18px',
              cursor: 'pointer',
            }}
          />
        )}
      </div>

      {/* Suggestions dropdown */}
      {suggestions.length > 0 && (
        <ul style={{
          listStyle: 'none',
          padding: 0,
          margin: '10px 0',
          border: '1px solid #ccc',
          borderRadius: '4px',
          maxWidth: '400px',
          marginLeft: 'auto',
          marginRight: 'auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          position: 'absolute',
          zIndex: '9999',
          backgroundColor: '#fff',
          maxHeight: '200px', // Set maximum height for 5-6 items
          overflowY: 'auto',  // Enable vertical scroll
        }}>
          {suggestions.map(fund => (
            <li
              key={fund.id}
              onClick={() => handleSuggestionClick(fund)}
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid #eee',
                backgroundColor: '#fff',
                transition: 'background-color 0.2s ease',
              }}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#f1f1f1'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#fff'}
            >
              {fund.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default MutualFunds;
